import { Modal } from 'antd';
import React, { Component } from 'react';

export class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDeleteModalVisible: false,
            confirmDeleteId: null
        };
    }
    confirmDelete = (id) => {
        this.setState({ confirmDeleteModalVisible: true, confirmDeleteId: id });
    }
    hideDelete = () => {
        this.setState({ confirmDeleteModalVisible: false, confirmDeleteId: null });
    }
    handleDelete = () => {
        const id = this.state.confirmDeleteId;
        this.props.onDelete(id);
        this.setState({ loading: false, confirmDeleteModalVisible: false });
    }
    render() {
        return <Modal
            title={`刪除${this.props.title}`}
            visible={this.state.confirmDeleteModalVisible}
            onOk={this.handleDelete}
            onCancel={this.hideDelete}
            okText="確定"
            cancelText="取消"
            confirmLoading={this.state.isLoading}
        >
            <p>你確定要刪除該{this.props.title}?</p>
        </Modal>;
    }
}

export default DeleteModal;
