import React, { Component } from 'react';
import { DatePicker, Form, Row, Spin, Tabs } from 'antd';
import moment from "moment";
import BrandSelect from '../../../components/BrandSelect';
import ActivitySelect from '../../../components/ActivitySelect';
import GoodHoDayAPI from '../../../api/GoodHoDayAPI';
import Breadcrumb from '../../../components/Breadcrumb';
import StoreRegionReportL1 from './store/StoreRegionReportL1';
import StoreRegionReportL2 from './store/StoreRegionReportL2';
import withRouterNavigateHook from '../../../hoc/withRouterNavigateHook';
const { RangePicker } = DatePicker;

export class AdminStoreReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandId: null,
      activityId: null,
      startDate: moment().subtract(1, 'month').format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      loading: false,
      couponUsageRankOption: null,
      activeTab: "all",
      selectedRegion: null,
    }
    this.l1Chart = React.createRef();
    this.l2Chart = React.createRef();
  }
  getQuery = () => {
    const query = {
      brand_id: this.state.brandId,
      activity_id: this.state.activityId,
      startdate: this.state.startDate,
      enddate: this.state.endDate,
    };
    return query;
  }
  refresh = async () => {
    //this.setState({ loading: true });
    //await this.updateCouponUsageRankChartOption(query);
    //this.setState({ loading: false });
  }
  updateCouponUsageRankChartOption = async (query) => {
    const response = await GoodHoDayAPI.reports.store.coupon(query);
    const result = response.data;
    //console.log('updatecouponUsageRankOption', result);
    const options = {
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01]
      },
      yAxis: {
        type: 'category',
        data: result.map((row) => row.storename)
      },
      series: [
        {
          name: '優惠碼',
          type: 'bar',
          data: result.map((row) => row.discounttimes)
        },
        {
          name: '來店碼',
          type: 'bar',
          data: result.map((row) => row.giveawaytimes)
        }
      ]
    };
    this.setState({ couponUsageRankOption: options })
    return options;
  }
  handleBrandChange = (value) => {
    this.setState({ brandId: value }, () => this.refresh());
  }
  handleActivityChange = (value) => {
    this.setState({ activityId: value }, () => this.refresh());
  }
  handlePeriodChange = (value) => {
    const [start, end] = value;
    this.setState({
      startDate: start.format("YYYY-MM-DD"),
      endDate: end.format("YYYY-MM-DD")
    }, () => this.refresh())
    console.log(value, start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
  }
  handleSelectRegion = (value, detail) => {
    //console.log("select region", value, detail)
    this.setState({
      activeTab: "region",
      selectedRegion: detail
    });
  }
  render() {
    return <div>
      <Breadcrumb />
      <h1>門市報表</h1>
      <Form layout="inline">
        <Form.Item>
          <RangePicker
            value={[moment(this.state.startDate), moment(this.state.endDate)]}
            onChange={this.handlePeriodChange}
            allowClear={false}
            placeholder={["開始日期", "結束日期"]} />
        </Form.Item>
        <Form.Item >
          <BrandSelect onChange={this.handleBrandChange} />
        </Form.Item>
        <Form.Item>
          <ActivitySelect brandid={this.state.brandId} onChange={this.handleActivityChange} />
        </Form.Item>
      </Form>
      <h3>各區域門市活動數據地圖</h3>
      <Spin spinning={this.state.loading}>
        <Row>
          <Tabs
            type="card"
            onChange={(key) => this.setState({ activeTab: key })}
            activeKey={this.state.activeTab}
            hideAdd={true}
          >
            <Tabs.TabPane tab="全區" key="all" style={{ padding: 3 }}>
              <StoreRegionReportL1 ref={this.l1Chart} onSelectRegion={this.handleSelectRegion} query={this.getQuery()} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="地區" key="region" disabled={!this.state.selectedRegion} style={{ padding: 3 }}>
              <StoreRegionReportL2 ref={this.l2Chart} region={this.state.selectedRegion} query={this.getQuery()} />
            </Tabs.TabPane>
          </Tabs>
        </Row>
        {/* <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h3>優惠券核銷排⾏</h3>
            <Card>
              <ReactEChartsLoadingWrapper style={{ height: 600 }} option={this.state.couponUsageRankOption} />
            </Card>
          </Col>
        </Row> */}
      </Spin>
    </div>;
  }
}

export default AdminStoreReportPage;
