import React, { Component } from 'react';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ResponsiveTable from '../../components/ResponsiveTable';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import ActivitySelect from '../../components/ActivitySelect';
import Breadcrumb from '../../components/Breadcrumb';

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    render: text => text,
  },
  {
    title: '電話',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: '領取優惠次數',
    key: 'couponcount',
    dataIndex: 'couponcount',
    sorter: (a, b) => a.couponcount - b.couponcount,
  },
  {
    title: '優惠碼使用次數',
    key: 'discounttimes',
    dataIndex: 'discounttimes',
    sorter: (a, b) => a.discounttimes - b.discounttimes,
  },
  {
    title: '來店碼使用次數',
    key: 'giveawaytimes',
    dataIndex: 'giveawaytimes',
    sorter: (a, b) => a.giveawaytimes - b.giveawaytimes,
  },
  // {
  //   title: '動作',
  //   key: 'action',
  //   render: (text, record) => (
  //     <Space size="middle">
  //       <a>修改</a>
  //       <a>刪除</a>
  //     </Space>
  //   ),
  // },
];
export class StoreCustomerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      customers: [],
      brandId: null,
      storeId: null,
      activityId: null,
      date: null,
      keyword: null,
      query: {},
      total: null,
      pageSize: 10,
    }
    this.form = React.createRef();
  }
  handleChangeStore = (storeId, record) => {
    //console.log(storeId, record);
    this.form.current.setFieldsValue({ activity_id: null });
    this.setState({ storeId: storeId, brandId: record.brand_id }, this.form.current.submit());
  }
  componentDidMount() {
    this.refresh();
  }
  refresh = (page, pageSize) => {
    let query = this.state.query;
    query.brand_id = this.state.brandId;
    if (!query.brand_id) return;
    this.setState({ loading: true });
    GoodHoDayAPI.customers.index(query, page, pageSize).then((response) => {
      const result = response.data;
      this.setState({
        customers: result.data,
        total: result.total,
        pageSize: result.per_page,
      });
    }).finally(() => {
      this.setState({ loading: false });
    })
  }
  // handleBrandChange = (brandId) => {
  //   this.form.current.setFieldsValue({ activity_id: null });
  //   this.setState({ brandId: brandId, activityId: null }, () => this.form.current.submit());
  // }
  handleActivityChange = (activityId) => {
    this.setState({ activityId: activityId }, () => this.form.current.submit());
  }
  handleDateChange = (date) => {
    this.setState({ date: date }, () => this.form.current.submit());
  }
  handleFinal = (values) => {
    //console.log("handleFinal", values);
    if (values.createddate)
      values.createddate = values.createddate.format("YYYY-MM-DD");
    values.store_id = this.state.storeId;
    this.setState({ query: values }, () => this.refresh());
  }
  render() {
    return <div>
      <Breadcrumb />
      <h1>顧客名單管理</h1>
      <Row style={{ marginBottom: 10 }} justify="end">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <AuthStoreSelect style={{ width: '100%' }} onChange={this.handleChangeStore} />
        </Col>
      </Row>
      <Form ref={this.form} onFinish={this.handleFinal}>
        <Row gutter={10}>
          {/* <Col xs={24} sm={6} md={6} lg={6} xl={6}>
            <Form.Item name="brand_id">
              <BrandSelect onChange={this.handleBrandChange} />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={10} md={10} lg={10} xl={10}>
            <Form.Item name="activity_id">
              <ActivitySelect brandid={this.state.brandId} onChange={this.handleActivityChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Form.Item name="createddate">
              <DatePicker style={{ width: '100%' }} placeholder="新增日期" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Form.Item name="keyword">
              <Input placeholder="關鍵字" allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查詢</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ margin: 10 }}>
        <Col span={24}>
          <Form layout="inline">
            <Form.Item>
              <DatePicker placeholder="填寫日期" />
            </Form.Item>
            <Form.Item>
              <Input placeholder="關鍵字" />
            </Form.Item>
            <Form.Item>
              <Button icon={<SearchOutlined />}>查詢</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <ResponsiveTable
        columns={columns}
        dataSource={this.state.customers}
        rowKey="id"
        pagination={{
          total: this.state.total,
          pageSize: this.state.pageSize,
          onChange: (page, pageSize) => this.refresh(page, pageSize),
        }}
      />
    </div>;
  }
}

export default StoreCustomerPage;
