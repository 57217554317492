import React from 'react'
import AnimatedNumber from "animated-number-react";
import Card from './Card';
import { Col, Row } from 'antd';

export default function DashboardCard(props) {
    return <Card style={{ padding: '16px 0' }}>
        <Row>
            <Col span={24} md={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{
                    width: 54,
                    height: 54,
                    backgroundColor: props.color ? props.color : '#BA59C7',
                    borderRadius: 27,
                    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <img style={{ margin: 'auto' }} src={props.img ? props.img : require('../images/icons/paper.png')} alt="paper" />
                </span>
            </Col>
            <Col span={24} md={16} style={{ textAlign: 'left', padding: 10 }}>
                <div style={{ fontSize: '1rem', fontWeight: '700', color: '#A4A3A3', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{props.title ? props.title : '-'}</div>
                <div style={{ fontSize: '1.6rem', fontWeight: '700', color: '#717171' }}>
                    <AnimatedNumber value={props.rate} formatValue={(value) => value.toFixed(props.fixed !== undefined ? props.fixed : 2)} />{props.unit !== undefined ? props.unit : '%'}
                </div>
                {props.count !== undefined &&
                    <div style={{ fontSize: '1rem', color: '#A4A3A3' }}>
                        <AnimatedNumber value={props.count} formatValue={(value) => value.toFixed(0)} /> 張
                    </div>
                }
            </Col>
        </Row>
    </Card>
}
