import React, { Component } from 'react'
import ReactEChartsLoadingWrapper from './ReactEChartsLoadingWrapper'
import * as echarts from 'echarts';
import taiwanJson from '../../map/taiwan.json';
import GoodHoDayAPI from '../../api/GoodHoDayAPI';

export class CustomerRegionChart extends Component {
    constructor(props) {
        super(props);
        this.result = [];
        this.state = {
            geoChartOption: null,
        }
        echarts.registerMap('taiwan', taiwanJson, {
            'Lienchiang County': {
                // Upper left longitude
                left: 119,
                // Upper left latitude
                top: 24,
                // Range of longitude
                width: 2
            },
            'Kinmen County': {
                // Upper left longitude
                left: 118.8,
                // Upper left latitude
                top: 23.8,
                // Range of longitude
                width: 0.2
            },
        });
    }
    componentDidMount() {
        // 若定義 lazyload 屬性則不在一開始就取
        if (!this.props.lazyLoad) {
            this.refresh();
        }
    }
    refresh = (query) => {
        this.updateGeoChartOption(query);
    }
    fetchRegions = (query) => {
        return GoodHoDayAPI.reports.store.region.index(query).then((response) => {
            const result = response.data;
            if (this.props.onFetchRegions) {
                this.props.onFetchRegions(result);
            }
            return response;
        });
    }
    updateGeoChartOption = async (query) => {
        const response = await this.fetchRegions(query);
        const result = response.data;
        // console.log(result);
        this.result = result;
        const data = result.details.map((row) => {
            return {
                name: row.name,
                value: row.consumeCount, // 核銷總數
            }
        });
        let max = Math.max(...data.map((row) => row.value));
        if (!max) max = 5;
        const roundedMax = Math.ceil(max / 5) * 5; // Math.pow(10, max.toString().length);
        // console.log("max", max, roundedMax);
        const options = {
            // title: {
            //   text: '台灣',
            //   subtext: '-',
            //   sublink: '-',
            //   left: 'left'
            // },
            // tooltip: {
            //   trigger: 'item',
            //   showDelay: 0,
            //   transitionDuration: 0.2
            // },
            visualMap: {
                type: 'piecewise',
                orient: 'horizontal',
                splitNumber: 4,
                itemWidth: 50,
                itemHeight: 8,
                itemGap: 5,
                //showLabel: true,
                itemSymbol: 'rect',
                left: 0,
                top: 5,
                min: 1,
                max: roundedMax,
                hoverLink: true,
                inRange: {
                    color: [
                        '#FCAC8C',
                        '#ee4829'
                    ]
                },
                target: {
                    outOfRange: {
                        color: '#ffc5a5'
                    },
                },
                text: [roundedMax, '核銷總數     1'],
                textGap: 10,
                calculable: true
            },
            toolbox: {
                show: true,
                feature: {
                    // dataView: { readOnly: false },
                    // restore: {},
                    saveAsImage: {
                        show: false,
                        // icon: `image://${require('../../../echart/echartDownload.png')}`,
                        // type: 'png',
                        pixelRatio: 2,
                        title: '下載'
                    }
                }
            },
            // https://echarts.apache.org/zh/option.html#series-map
            series: [
                {
                    zoom: 1,
                    name: 'Taiwan',
                    type: 'map',
                    aspectScale: 1.0, // Aspect Ratio
                    selectedMode: 'single', // Selection type
                    roam: false, // Mouse zoomable
                    map: 'taiwan',
                    itemStyle: {
                        areaColor: '#ffc5a5',
                        borderColor: '#fff'
                    },
                    silent: false, // No selection
                    label: {
                        show: false, // Showing region label
                    },
                    select: {
                        label: {
                            show: false,
                            color: '#000'
                        },
                        itemStyle: {
                            areaColor: '#c21161'
                        }
                    },
                    emphasis: { // Hover text label
                        label: {
                            show: true,
                            color: '#000'
                        },
                        itemStyle: {
                            areaColor: 'rgba(186, 89, 199, 1)',
                        }
                    },
                    data: data,
                    nameMap: {
                        'Taipei City': '台北市',
                        'New Taipei City': '新北市',
                        'Taoyuan County': '桃園市',
                        'Taichung City': '台中市',
                        'Tainan City': '台南市',
                        'Kaohsiung City': '高雄市',
                        'Hsinchu County': '新竹縣',
                        'Miaoli County': '苗栗縣',
                        'Changhua County': '彰化縣',
                        'Nantou County': '南投縣',
                        'Yunlin County': '雲林縣',
                        'Chiayi County': '嘉義縣',
                        'Pingtung County': '屏東縣',
                        'Yilan County': '宜蘭縣',
                        'Hualien County': '花蓮縣',
                        'Taitung County': '台東縣',
                        'Penghu County': '澎湖縣',
                        'Kinmen County': '金門縣',
                        'Lienchiang County': '連江縣',
                        'Keelung City': '基隆市',
                        'Hsinchu City': '新竹市',
                        'Chiayi City': '嘉義市',
                    }
                }
            ]
        };
        this.setState({ geoChartOption: options });
    }
    handleGeoEvent = (eChartEvent) => {
        const name = eChartEvent.name;
        // const target = eChartEvent.event.target;
        //console.log("handleGeoEvent", name, eChartEvent.dataIndex, eChartEvent);
        if (this.props.onChange) {
            const detail = this.result.details.find((row) => row.name === name);
            this.props.onChange(name, detail);
        }
    }
    render() {
        return (
            <div>
                <ReactEChartsLoadingWrapper option={this.state.geoChartOption} style={{ height: 800, width: '100%' }} xs={400} onEvents={{
                    'click': this.handleGeoEvent,
                }} />
            </div>
        )
    }
}

export default CustomerRegionChart