import React from 'react'

export default function InfoCard(props) {
    const usePropValue = props.value !== undefined;
    return (
        <div style={{
            marginTop: 20,
            marginBottom: 20,
            borderRadius: 5,
            padding: 15,
            boxShadow: '0px 0px 7.63889px rgba(0, 0, 0, 0.2)',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
            borderColor: 'rgba(253, 88, 57, 1)',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: usePropValue ? 'row' : 'column',
            ...props.style
        }}>
            <div style={{ flex: 2, color: 'rgba(164, 163, 163, 1)', lineHeight: '2rem', fontWeight: 500 }}>{props.title}</div>
            <div style={{ flex: 2, color: 'rgba(255, 131, 62, 1)', fontSize: '2rem', lineHeight: '2rem', fontWeight: 700, textAlign: 'right' }}>{usePropValue ? props.value : props.children}</div>
        </div>
    )
}
