export default class PermissionManager {
    static appComponent = null;
    static roles = [];
    static ROLE_ADMIN = "admin";
    static ROLE_BRAND = "brand_manager";
    static ROLE_CLERK = "clerk";

    static init(appComponent) {
        this.appComponent = appComponent;
    }
    static updateRoles(roles) {
        this.roles = roles;
        this.appComponent.updateRoles(roles);
    }
    static getRoles() {
        return this.roles;
    }
    static getRoutes() {
        return [
            // {
            //     key: "home",
            //     path: "/home",
            //     title: "Home",
            //     icon: <HomeOutlined />,
            //     component: <HomePage />,
            //     roles: [ROLE_DEMO, ROLE_READ, ROLE_WRITE],
            //     locked: false,
            // },
            // {
            //     key: "overview",
            //     path: "/overview",
            //     title: "Overview",
            //     icon: <DashboardOutlined />,
            //     component: <OverviewPage />,
            //     roles: [ROLE_DEMO, ROLE_READ, ROLE_WRITE],
            //     locked: false,
            // },
            // {
            //     key: "analysis",
            //     path: "/analysis",
            //     title: "Analysis",
            //     icon: <BarChartOutlined />,
            //     component: <AnalysisPage />,
            //     roles: [ROLE_DEMO, ROLE_READ, ROLE_WRITE],
            //     locked: false,
            // },
            // {
            //     key: "energy-balancing",
            //     path: "/energy-balancing",
            //     title: "Energy Balancing",
            //     icon: <SlidersOutlined />,
            //     component: <EnergyBalancingPage />,
            //     roles: [ROLE_READ, ROLE_WRITE],
            //     locked: false,
            // },
            // {
            //     key: "error-log",
            //     path: "/error-log",
            //     title: "Error Log",
            //     icon: <InfoCircleOutlined />,
            //     component: <ErrorLogPage />,
            //     roles: [ROLE_WRITE],
            //     locked: false,
            // },
            // {
            //     key: "setting",
            //     path: "/setting",
            //     title: "Setting",
            //     icon: <SettingOutlined />,
            //     component: <SettingPage />,
            //     roles: [ROLE_WRITE],
            //     locked: false,
            // },
            // {
            //     key: "live-screen",
            //     path: "/live-screen",
            //     title: "Live Screen",
            //     icon: <FundProjectionScreenOutlined />,
            //     component: <LiveScreenPage />,
            //     roles: [ROLE_READ, ROLE_WRITE],
            //     locked: false,
            // },
            // {
            //     key: "test",
            //     path: "/test",
            //     title: "Test",
            //     icon: <BugOutlined />,
            //     component: <TestPage />,
            //     roles: [ROLE_DEMO, ROLE_READ, ROLE_WRITE],
            //     locked: true,
            // },
        ];
    }
    static getRoutesByRole() {
        return this.getRoutes().filter(
            (route) => route.roles.indexOf(this.role) >= 0
        );
    }

}