import React, { Component } from 'react'
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import ReactEChartsLoadingWrapper from './ReactEChartsLoadingWrapper';

export class CustomerStageFunnelChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: null
        };
    }
    componentDidMount() {
        this.refresh(this.props.query);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.query !== this.props.query) {
            this.refresh(this.props.query);
        }
    }
    refresh = async (query) => {
        const response = await GoodHoDayAPI.reports.activity.stage(query);
        const result = response.data;
        const maxValue = Math.max(...result.map((row) => row.count));
        const options = {
            // tooltip: {
            //     trigger: 'item',
            //     formatter: (params) => `轉換率 ${Math.round(params.data.conversionRate * 10000)/100}%`
            // },
            series: [
                {
                    name: '顧客消費漏斗',
                    type: 'funnel',
                    min: 0,
                    max: maxValue,
                    minSize: '30%',
                    maxSize: '100%',
                    sort: 'descending',
                    gap: 2,
                    label: {
                        show: true,
                        position: 'inside',
                        color: '#fff',
                        fontSize: 12,
                        formatter: '{b}\n\n{valueStyle|{c}}',
                        rich: {
                            valueStyle: {
                                fontWeight: 900,
                                fontSize: 36,
                            }
                        }
                    },
                    emphasis: {
                        label: {
                            color: '#fff',
                            fontSize: 12,
                            formatter: (params) => `${params.data.name}  {valueStyle|${params.data.count}}  未${params.data.name}  {valueStyle|${params.data.undoneCount}}\n轉換率  {valueStyle|${Math.round(params.data.conversionRate * 10000)/100}%}`,
                            rich: {
                                valueStyle: {
                                    fontWeight: 700,
                                    fontSize: 16,
                                    lineHeight: 20,
                                }
                            }
                        }
                    },
                    data: result.map((row) => {
                        const result = {
                            ...row,
                            name: row.name,
                            value: row.count,
                        };
                        return result;
                    })
                }
            ]
        };
        this.setState({ options: options })
        return options;
    }
    render() {
        return (
            <ReactEChartsLoadingWrapper ref={this.chart} option={this.state.options} style={{ height: 500, ...this.props.style }} />
        )
    }
}

export default CustomerStageFunnelChart