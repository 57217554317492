import { Col, Row, DatePicker, Spin, Form } from 'antd';
import React, { Component } from 'react';
import BrandSelect from '../../../components/BrandSelect';
import ActivitySelect from '../../../components/ActivitySelect';
import moment from "moment";
import GoodHoDayAPI from '../../../api/GoodHoDayAPI';
import Breadcrumb from '../../../components/Breadcrumb';
import Card from '../../../components/Card';
import ReactEChartsLoadingWrapper from '../../../components/chart/ReactEChartsLoadingWrapper';
import InfoCard from '../../../components/InfoCard';
import CustomerRegionChart from '../../../components/chart/CustomerRegionChart';
import SvgIconPath from '../../../api/SvgIconPath';
const { RangePicker } = DatePicker;

export class AdminCustomerReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandId: null,
      activityId: null,
      startDate: moment().subtract(1, 'month').format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      loading: false,
      storeConsumeTimeOption: null,
      couponIssueByDateOption: null,
      couponIssueByTimeHeatmapOption: null,
      arriveByReferOption: null,
      customerRegionByNearestStoreOption: null,
      customerRegionByRedeemStoreOption: null,
      customerTypeStatOption: null,
      redeemByRegionOption: null,
      selectedRegionData: {},
    };
    this.regionChart = React.createRef();
  }
  refresh = async () => {
    this.setState({ loading: true });
    const query = {
      brand_id: this.state.brandId,
      activity_id: this.state.activityId,
      startdate: this.state.startDate,
      enddate: this.state.endDate,
    };
    await this.updateStoreConsumeTimeChartOption(query);
    await this.updateCouponIssueByDateChartOption(query);
    await this.updateArriveByReferChartOption(query);
    await this.updateCustomerRegionByNearestStoreChartOption(query);
    await this.updateCustomerRegionByRedeemStoreChartOption(query);
    await this.updateCustomerTypeStatChartOption(query);
    await this.updateCouponIssueByTimeHeatmapOption(query);
    if (this.regionChart.current) {
      this.regionChart.current.refresh(query);
    }
    this.setState({ loading: false });
  }
  updateStoreConsumeTimeChartOption = async (query) => {
    const response = await GoodHoDayAPI.reports.customer.consumeTime(query);
    const result = response.data;
    const options = {
      legend: {
        top: 5,
        left: 0,
        icon: SvgIconPath.lineChartLegend,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      tooltip: {},
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: result.map((row) => row.date),
        axisLabel: {
          formatter: (value, index) => {
            const [year, month, day] = value.split('-');
            return `${year}\n{bold|${month}}\n{bold|${day}}`;
          },
          rich: {
            bold: {
              fontWeight: 700
            }
          }
        }
      },
      yAxis: {
        type: 'value',
        name: '次數',
        alignTicks: true,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: '{value}'
        }
      },
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [{
        type: 'line',
        name: '優惠碼',
        smooth: true,
        data: result.map((row) => row.discountusedtimes)
      }, {
        type: 'line',
        name: '來店碼',
        smooth: true,
        data: result.map((row) => row.giveawayusedtimes)
      }]
    };
    this.setState({ storeConsumeTimeOption: options });
    return options;
  };
  updateCouponIssueByDateChartOption = async (query) => {
    const response = await GoodHoDayAPI.reports.customer.couponIssue(query);
    const result = response.data;
    const data = result.map((row) => [row.date, row.coupontotal]);
    const options = {
      tooltip: {
        trigger: 'axis',
        position: function (pt) {
          return [pt[0], '10%'];
        }
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        }
      ],
      series: [
        {
          name: '領券時間',
          type: 'line',
          smooth: true,
          symbol: 'none',
          areaStyle: {},
          data: data
        }
      ]
    };
    this.setState({ couponIssueByDateOption: options });
    return options;
  }
  updateArriveByReferChartOption = async (query) => {
    const response = await GoodHoDayAPI.reports.customer.referrer(query);
    const result = response.data;
    //console.log(result);
    const options = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: 5,
        left: 0,
        icon: 'circle'
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      series: [
        {
          name: '進站來源',
          type: 'pie',
          radius: [70, 100],
          center: ['50%', '50%'],
          label: {
            show: false,
            width: 100,
            height: 100,
            borderRadius: 50,
            backgroundColor: 'rgba(230, 230, 230, 1)',
            fontSize: '16',
            fontWeight: 300,
            position: 'center',
            color: 'rgba(113, 113, 113, 1)',
            formatter: '\n\n{b}\n{valueStyle|{d}%}',
            rich: {
              valueStyle: {
                fontWeight: 700,
                fontSize: 18,
                lineHeight: 30,
              }
            },
          },
          emphasis: {
            label: {
              show: true,
            }
          },
          labelLine: {
            show: false
          },
          data: result.map((row) => { return { name: row.referrer, value: row.count } })
        }
      ]
    };
    this.setState({ arriveByReferOption: options });
    return options;
  }
  updateCustomerRegionByNearestStoreChartOption = async (query) => {
    const response = await GoodHoDayAPI.reports.customer.regionByNearestStore(query);
    const result = response.data;
    //console.log(result);
    const options = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: 'bottom'
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      series: [
        {
          name: '顧客最近門市地區',
          type: 'pie',
          radius: [70, 100],
          center: ['50%', '50%'],
          label: {
            show: false,
            width: 100,
            height: 100,
            borderRadius: 100,
            backgroundColor: 'rgba(230, 230, 230, 1)',
            fontSize: '16',
            fontWeight: 300,
            position: 'center',
            color: 'rgba(113, 113, 113, 1)',
            formatter: '\n\n{b}\n{valueStyle|{d}%}',
            rich: {
              valueStyle: {
                fontWeight: 700,
                fontSize: 18,
                lineHeight: 30,
              }
            },
          },
          data: result.map((row) => { return { name: row.storename, value: row.customertotal } })
        }
      ]
    };
    this.setState({ customerRegionByNearestStoreOption: options });
    return options;
  }
  updateCustomerRegionByRedeemStoreChartOption = async (query) => {
    const response = await GoodHoDayAPI.reports.customer.regionByRedeemStore(query);
    const result = response.data;
    //console.log(result);
    const options = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: 'bottom'
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      series: [
        {
          name: '顧客核銷門市地區',
          type: 'pie',
          radius: [70, 100],
          label: {
            show: false,
            width: 100,
            height: 100,
            borderRadius: 100,
            backgroundColor: 'rgba(230, 230, 230, 1)',
            fontSize: '16',
            fontWeight: 300,
            position: 'center',
            color: 'rgba(113, 113, 113, 1)',
            formatter: '\n\n{b}\n{valueStyle|{d}%}',
            rich: {
              valueStyle: {
                fontWeight: 700,
                fontSize: 18,
                lineHeight: 30,
              }
            },
          },
          center: ['50%', '50%'],
          data: result.map((row) => { return { name: row.storename, value: row.customertotal } })
        }
      ]
    };
    this.setState({ customerRegionByRedeemStoreOption: options });
    return options;
  }
  updateCustomerTypeStatChartOption = async (query) => {
    const response = await GoodHoDayAPI.reports.customer.customerType(query);
    const result = response.data;
    //console.log(result);
    const options = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: 5,
        left: 0,
        icon: 'circle'
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      series: [
        {
          name: '新舊客比例',
          type: 'pie',
          radius: [70, 100],
          data: result.map((row) => { return { name: row.customertype, value: row.total } }),
          label: {
            show: false,
            width: 100,
            height: 100,
            borderRadius: 100,
            backgroundColor: 'rgba(230, 230, 230, 1)',
            fontSize: '16',
            fontWeight: 300,
            position: 'center',
            color: 'rgba(113, 113, 113, 1)',
            formatter: '\n\n{b}\n{valueStyle|{d}%}',
            rich: {
              valueStyle: {
                fontWeight: 700,
                fontSize: 18,
                lineHeight: 30,
              }
            },
          },
          emphasis: {
            label: {
              show: true,
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    this.setState({ customerTypeStatOption: options });
    return options;
  }
  updateRedeemByRegionOption = async (regionDetail) => {
    const options = {
      color: ['rgb(255, 131, 62)', 'rgba(113, 113, 113, 1)'],
      series: [
        {
          name: '活動核銷比例',
          type: 'pie',
          radius: [70, 100],
          data: [
            { name: '已核銷', value: regionDetail.totalConsumeRate, },
            { name: '未核銷', value: 1 - regionDetail.totalConsumeRate, },
          ],
          label: {
            show: false,
            fontSize: '20',
            fontWeight: 300,
            position: 'center',
            color: 'rgb(255, 131, 62)',
            formatter: '\n{b}\n{valueStyle|{d}%}',
            rich: {
              valueStyle: {
                fontWeight: 700,
                fontSize: 20,
                lineHeight: 30,
              }
            },
          },
          emphasis: {
            label: {
              show: true,
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    this.setState({ redeemByRegionOption: options });
  }
  updateCouponIssueByTimeHeatmapOption = async (query) => {
    const response = await GoodHoDayAPI.reports.customer.couponIssueTime(query);
    const result = response.data;
    // console.log(result);

    const hours = result[0].details.map((time) => time.name);
    const days = result.map((day) => day.weekname);
    const data = [];

    let max = 0;
    result.forEach((day, dIndex) => {
      day.details.forEach((time, tIndex) => {
        if (time.issueCount > max) max = time.issueCount;
        data.push([dIndex, tIndex, time.issueCount])
      });
    });
    if (!max) max = 10;
    //console.log("data", max, data);

    // const hours = [
    //   '12a', '1a', '2a', '3a', '4a', '5a', '6a',
    //   '7a', '8a', '9a', '10a', '11a',
    //   '12p', '1p', '2p', '3p', '4p', '5p',
    //   '6p', '7p', '8p', '9p', '10p', '11p'
    // ];
    // // prettier-ignore
    // const days = [
    //   'Saturday', 'Friday', 'Thursday',
    //   'Wednesday', 'Tuesday', 'Monday', 'Sunday'
    // ];
    const option = {
      grid: {
        top: '10%',
        left: '15%',
        right: '5%',
        bottom: '10%'
      },
      tooltip: {
        position: 'top',
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: days,
        splitArea: {
          show: true
        }
      },
      yAxis: {
        type: 'category',
        data: hours,
        splitArea: {
          show: true
        }
      },
      visualMap: {
        type: 'piecewise',
        orient: 'horizontal',
        splitNumber: 4,
        itemWidth: 50,
        itemHeight: 8,
        itemGap: 5,
        //showLabel: true,
        itemSymbol: 'rect',
        left: 0,
        top: 5,
        min: 1,
        max: max,
        calculable: true,
        color: ["rgba(194,17,97,1)", "rgba(255,204,227,1)"],
        text: [max, '數量     1'],
        textGap: 10,
      },
      series: [
        {
          name: '領券數',
          type: 'heatmap',
          data: data,
          label: {
            show: false,
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    this.setState({ couponIssueByTimeHeatmapOption: option });

  }
  handleBrandChange = (value) => {
    this.setState({ brandId: value }, () => this.refresh());
  }
  handleActivityChange = (value) => {
    this.setState({ activityId: value }, () => this.refresh());
  }
  handlePeriodChange = (value) => {
    const [start, end] = value;
    this.setState({
      startDate: start.format("YYYY-MM-DD"),
      endDate: end.format("YYYY-MM-DD")
    }, () => this.refresh())
    console.log(value, start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
  }
  handleRegionChange = (value, detail, dataIndex, event) => {
    this.setState({
      selectedRegionData: detail
    });
    this.updateRedeemByRegionOption(detail);
    //console.log("AdminCustomerReportPage: handleRegionChange", value, detail, dataIndex, event);
  }
  render() {
    return <div>
      <Breadcrumb />
      <h1>顧客行為報表</h1>
      <Form layout="inline">
        <Form.Item>
          <RangePicker
            value={[moment(this.state.startDate), moment(this.state.endDate)]}
            onChange={this.handlePeriodChange}
            allowClear={false}
            placeholder={["開始日期", "結束日期"]} />
        </Form.Item>
        <Form.Item >
          <BrandSelect onChange={this.handleBrandChange} />
        </Form.Item>
        <Form.Item>
          <ActivitySelect brandid={this.state.brandId} onChange={this.handleActivityChange} />
        </Form.Item>
      </Form>
      <Spin spinning={this.state.loading}>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>來店消費時間分析</h3>
            <Card>
              <ReactEChartsLoadingWrapper option={this.state.storeConsumeTimeOption} style={{ height: 300 }} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>線上領券時間分析</h3>
            <Card>
              {/* <ReactEChartsLoadingWrapper option={this.state.couponIssueByDateOption} style={{ height: 300 }} /> */}
              <ReactEChartsLoadingWrapper option={this.state.couponIssueByTimeHeatmapOption} style={{ height: 300 }} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>進站來源媒介</h3>
            <Card>
              <ReactEChartsLoadingWrapper option={this.state.arriveByReferOption} style={{ height: 300 }} />
            </Card>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <h3>顧客地區分布</h3>
            <Card>
              <Row>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <CustomerRegionChart ref={this.regionChart} lazyload={true} onChange={this.handleRegionChange} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <div style={{ textAlign: 'right', color: 'rgba(113, 113, 113, 1)' }}>可點選區域以查看詳細數據</div>
                  <InfoCard title="縣市區域" value={this.state.selectedRegionData.name} />
                  <InfoCard title="分店數量" value={this.state.selectedRegionData.storeCount} />
                  <InfoCard title="活動領取總數" value={this.state.selectedRegionData.issueCount} />
                  <InfoCard title="活動核銷總數" value={this.state.selectedRegionData.consumeCount} />
                  <InfoCard title="活動核銷比例">
                    <ReactEChartsLoadingWrapper option={this.state.redeemByRegionOption} style={{ height: 300 }} />
                  </InfoCard>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>顧客地區分布 (最近門市)</h3>
            <Card>
              <ReactEChartsLoadingWrapper option={this.state.customerRegionByNearestStoreOption} style={{ height: 300 }} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>顧客地區分布 (核銷門市)</h3>
            <Card>
              <ReactEChartsLoadingWrapper option={this.state.customerRegionByRedeemStoreOption} style={{ height: 300 }} />
            </Card>
          </Col> */}
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>新舊客⽐例</h3>
            <Card>
              <ReactEChartsLoadingWrapper option={this.state.customerTypeStatOption} style={{ height: 300 }} />
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>;
  }
}

export default AdminCustomerReportPage;
