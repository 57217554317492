import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withRouterNavigateHook from '../hoc/withRouterNavigateHook';
import PublicPage from './PublicPage';

export class NotFoundPage extends Component {
    render() {
        return <PublicPage>
            <div style={{ textAlign: 'center' }}>
                <h1>無法存取頁面</h1>
                您的權限不足或頁面不存在，請點選 <Link to="/">此處</Link> 返回登入頁
            </div>
        </PublicPage>;
    }
}

export default withRouterNavigateHook(NotFoundPage);
