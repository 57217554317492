import { Select } from 'antd';
import React, { Component } from 'react';
import GoodHoDayAPI from '../api/GoodHoDayAPI';

export class RegionSelect extends Component {
    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            regions: [],
        };
    }
    componentDidMount() {
        this.refresh();
    }
    refresh = () => {
        this.setState({ loading: true });
        GoodHoDayAPI.regions.index({}, 1, 999).then((response) => {
            const regions = response.data.data;
            this.setState({ regions: regions });
        }).finally(() => {
            this.setState({ loading: false });
        })
    }
    render() {
        return <Select
            loading={this.state.loading}
            placeholder="地區"
            showSearch
            optionFilterProp="name"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            {...this.props}>
            {this.state.regions.map((region) => <Select.Option key={region.id} value={region.id}>{region.name}</Select.Option>)}
        </Select>;
    }
}

export default RegionSelect;
