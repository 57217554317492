import { Button, Form, Input, notification } from 'antd'
import React, { Component } from 'react'
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import BrandSelect from '../../components/BrandSelect';
import Breadcrumb from '../../components/Breadcrumb';
import RegionSelect from '../../components/RegionSelect';
import StoreClerkTable from '../../components/StoreClerkTable';
import withRouterParamsHook from '../../hoc/withRouterParamsHook';

const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};
export class AdminStoreEditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            mode: 'store',
            storeId: null
        };
        this.form = React.createRef();
    }
    componentDidMount() {
        const storeId = this.props.params.storeId;
        this.fetchData(storeId);
    }
    fetchData = (storeId) => {
        if (!storeId) return;
        GoodHoDayAPI.stores.show(storeId).then((response) => {
            const storeData = response.data;
            this.setState({
                mode: 'edit',
                storeId: storeId
            });
            this.form.current.setFieldsValue(storeData);
        });
    }
    handleFinish = (values) => {
        console.log("handleFinish", values);
        if (this.state.mode === 'store') {
            this.setState({ loading: true });
            GoodHoDayAPI.stores.store(values).then((response) => {
                notification.info({ message: '新增成功' });
                this.fetchData(response.data.id);
            }).finally(() => {
                this.setState({ loading: false });
            });
        } else {
            this.setState({ loading: true });
            const storeId = this.state.storeId;
            GoodHoDayAPI.stores.update(storeId, values).then((response) => {
                notification.info({ message: '修改成功' });
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
    }
    render() {
        return (
            <div>
                <Breadcrumb />
                <h1>{this.state.mode === 'store' ? '新增' : '修改'}門市</h1>
                <Form ref={this.form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={this.handleFinish}>
                    <Form.Item label="品牌" name="brand_id">
                        <BrandSelect />
                    </Form.Item>
                    <Form.Item label="門市名稱" name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item name="tel" label="門市電話">
                        <Input placeholder="02-12345678" />
                    </Form.Item>
                    <Form.Item label="門市地址" style={{ marginBottom: 0 }}>
                        <Form.Item name="region_id" style={{ display: 'inline-block', width: 'calc(20% - 10px)', marginRight: 10 }}>
                            <RegionSelect />
                        </Form.Item>
                        <Form.Item name="address" style={{ display: 'inline-block', width: 'calc(80% - 10px)' }}>
                            <Input placeholder="地址" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="facebookurl" label="門市粉專">
                        <Input placeholder="https://www.facebook.com/" />
                    </Form.Item>
                    <Form.Item name="opentime" label="營業時間">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="email" label="門市 Email">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="gpslocation"
                        rules={[{ pattern: '^([-+]?)([\\d]{1,2})(((\\.)(\\d+)(,)))(\\s*)(([-+]?)([\\d]{1,3})((\\.)(\\d+))?)$', message: '格式錯誤，例: 22.6304461,120.2844837' }]}
                        label="GPS 位置"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} style={{ marginRight: 10 }}>
                            送出
                        </Button>
                        <Button htmlType="button">
                            重設
                        </Button>
                    </Form.Item>
                </Form>
                <h2>門市聯絡人</h2>
                <StoreClerkTable storeid={this.state.storeId} />
            </div>
        )
    }
}

export default withRouterParamsHook(AdminStoreEditPage);