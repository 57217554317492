import React, { Component } from 'react';
import './StoreScanPage.css';
import { Button, Col, Descriptions, Form, Input, notification, Row, Select } from 'antd';
import withRouterSearchParamsHooks from '../../hoc/withRouterSearchParamsHooks';
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import QrCodeScanScreen from '../../components/QrCodeScanScreen';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import Breadcrumb from '../../components/Breadcrumb';

export class StoreScanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: null,
      results: null,
      scanner: false,
      storeId: null,
      brandId: null,
      clerks: [],
    };
    this.onNewScanResult = this.onNewScanResult.bind(this);
    this.form = React.createRef();
  }
  componentDidMount() {
    if (this.props.searchParams) {
      const [searchParams, setSearchParams] = this.props.searchParams;
      if (searchParams.get('scanner')) {
        console.log("Show scannner");
        this.showScanner();
      }
    }
    const loginUserId = GoodHoDayAPI.auth.getLoginData().id;
    //console.log("default select id", loginUserId);
    this.form.current.setFieldsValue({ 'storeuser_id': loginUserId });
  }
  onNewScanResult(decodedText, decodedResult) {
    // Handle the result here.
    console.log("onNewScanResult", decodedResult);
    this.setState({
      couponCode: decodedText
    });
  }
  handleScan = couponCode => {
    if (couponCode) {
      this.setState({
        couponCode: couponCode,
        scanner: false,
      }, () => this.handleQuery());
    }
  }
  handleError = err => {
    console.error(err)
  }
  showScanner = () => {
    this.setState({
      scanner: true
    })
  }
  hideScanner = () => {
    this.setState({
      scanner: false
    })
  }
  handleInputCouponCode = (e) => {
    this.setState({ couponCode: e.target.value });
  }
  refreshClerks = () => {
    GoodHoDayAPI.stores.clerks.index(this.state.storeId, 1, 999).then((response) => {
      const result = response.data;
      this.setState({ clerks: result.data });
    });
  }
  handleQuery = () => {
    this.setState({ loading: true });
    if (!this.state.storeId) {
      notification.warn({ message: '請先選擇門市' });
      return;
    }
    GoodHoDayAPI.coupons.showByCode(this.state.storeId, this.state.couponCode).then((response) => {
      console.log(response);
      this.setState({
        results: response.data
      });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }
  reset = () => {
    this.form.current.resetFields();
    this.setState({
      couponCode: null,
      results: null,
    });
  }
  handleFinish = (values) => {
    if (!this.state.results) return;
    GoodHoDayAPI.coupons.redeemByCode(this.state.storeId, this.state.couponCode, values).then((response) => {
      console.log(response);
      this.reset();
      notification.info({ message: '核銷成功' });
    });
  }
  handleChangeStore = (storeId, record) => {
    this.setState({ storeId: storeId, brandId: record.brand_id }, () => this.refreshClerks());
  }
  render() {
    return <div>
      <Breadcrumb />
      <h1>掃描頁面</h1>
      <Row style={{ marginBottom: 10 }} justify="end">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <AuthStoreSelect style={{ width: '100%' }} onChange={this.handleChangeStore} />
        </Col>
      </Row>
      <QrCodeScanScreen visible={this.state.scanner} onScan={this.handleScan} onClose={this.hideScanner} onError={this.handleError} />
      <Form ref={this.form} onFinish={this.handleFinish}>
        <Descriptions size="small" layout="vertical" column={2} title="優惠券內容" bordered extra={<Button type="primary" onClick={this.showScanner}>掃描</Button>}>
          <Descriptions.Item label="優惠券代碼" span={2}>
            <Row gutter={10}>
              <Col span={18}><Input onChange={this.handleInputCouponCode} value={this.state.couponCode} /></Col>
              <Col span={6}><Button type="primary" onClick={this.handleQuery}>查詢</Button></Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="活動名稱" span={2}>
            {this.state.results ? this.state.results.activity.name : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="門市名稱" span={2}>
            {this.state.results ? this.state.results.storename : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="門市 E-Mail" span={2}>
            {this.state.results ? this.state.results.storeemail : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="姓名">
            {this.state.results ? this.state.results.customer.name : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="電話">
            {this.state.results ? this.state.results.customer.phone : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="E-Mail" span={2}>
            {this.state.results ? this.state.results.customer.email : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="填寫時間">
            {this.state.results ? this.state.results.createdtime : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="優惠類型">
            {this.state.results ? (this.state.results.type === 'discount' ? '消費優惠' : '來店優惠') : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="訂單編號" span={2}>
            <Form.Item
              name="orderserialno"
              requiredMark={true}
              rules={[{ required: false, message: '請輸入訂單編號' }]}
              style={{ marginBottom: 0 }}>
              <Input />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="核銷人員" span={2}>
            <Form.Item name="storeuser_id" style={{ marginBottom: 0 }}>
              <Select>
                {this.state.clerks.map((clerk) => <Select.Option key={clerk.user_id} value={clerk.user_id}>{clerk.name} ({clerk.email})</Select.Option>)}
              </Select>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" disabled={!this.state.results} style={{ marginRight: 10 }}>核銷</Button>
            <Button onClick={this.reset}>重設</Button>
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </div>;
  }
}

export default withRouterSearchParamsHooks(StoreScanPage);
