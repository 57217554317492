import { Select } from 'antd';
import React, { Component } from 'react';
import GoodHoDayAPI from '../api/GoodHoDayAPI';

export class LoginBrandSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            loading: false,
        };
    }
    componentDidMount() {
        this.refresh();
    }
    refresh = () => {
        this.setState({ loading: true });
        GoodHoDayAPI.auth.brands({}, 1, 999).then((response) => {
            const brands = response.data.data;
            this.setState({ options: brands });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    render() {
        return <Select placeholder="品牌" loading={this.state.loading} allowClear style={{ minWidth: 150, marginRight: 10 }} {...this.props}>
            {this.state.options.map((option) => <Select.Option key={option.id} value={option.id}>{option.name} ({option.id})</Select.Option>)}
        </Select>;
    }
}

export default LoginBrandSelect;
