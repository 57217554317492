import { Button, Collapse, Form, Input, InputNumber, Modal, notification, Select, Table, Typography } from 'antd';
import React, { Component } from 'react';
import { Space } from 'antd';
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import DeleteModal from '../../components/DeleteModal';
import Breadcrumb from '../../components/Breadcrumb';

const { Panel } = Collapse;

export class AdminSettingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smtpLoading: false,

      recipientLoading: false,
      recipients: [],
      recipientEditMode: 'store',
      showEditRecipientModal: false,
    }
    this.form = React.createRef();
    this.recipientForm = React.createRef();
    this.deleteRecipientModel = React.createRef();
  }
  componentDidMount() {
    this.fetchEmailSmtp();
    this.fetchEmailRecipients();
  }
  fetchEmailSmtp = () => {
    this.setState({ smtpLoading: true });
    GoodHoDayAPI.configs.email.smtp.show().then((response) => {
      const result = response.data;
      this.form.current.setFieldsValue(result);
    }).finally(() => {
      this.setState({ smtpLoading: false });
    });
  }
  handleChangeEncryption = (value) => {
    let port = null;
    switch (value) {
      case 'ssl':
        port = 465; break;
      case 'tls':
        port = 587; break;
      default:
        port = 25; break;
    }
    this.form.current.setFieldsValue({ port: port });
  }
  saveEmailSmtp = (values) => {
    this.setState({ smtpLoading: true });
    GoodHoDayAPI.configs.email.smtp.store(values).then((response) => {
      notification.info({ message: '儲存成功' });
    }).finally(() => {
      this.setState({ smtpLoading: false });
    });
  }
  fetchEmailRecipients = () => {
    this.setState({ recipientLoading: true });
    GoodHoDayAPI.configs.email.recipient.index({}, 1, 999).then((response) => {
      const result = response.data;
      this.setState({ recipients: result.data });
    }).finally(() => {
      this.setState({ recipientLoading: false });
    });
  }
  showEditRecipientModal = (id) => {
    this.setState({ showEditRecipientModal: true });
    if (id) {
      this.fetchRecipient(id);
    }
  }
  hideEditRecipientModal = () => {
    this.setState({ showEditRecipientModal: false })
  }
  fetchRecipient = (id) => {
    if (!id) return;
    GoodHoDayAPI.configs.email.recipient.show(id).then((response) => {
      const result = response.data;
      console.log(result);
      this.recipientForm.current.setFieldsValue(result);
      this.setState({
        recipientEditMode: 'edit',
        recipientId: id,
      })
    });
  }
  handleEditRecipient = (values) => {
    if (this.state.recipientEditMode === 'store') {
      this.setState({ recipientLoading: true });
      GoodHoDayAPI.configs.email.recipient.store(values).then((response) => {
        notification.info({ message: '新增成功' });
        this.fetchEmailRecipients();
      }).finally(() => {
        this.setState({ recipientLoading: false });
      });
    } else {
      this.setState({ recipientLoading: true });
      GoodHoDayAPI.configs.email.recipient.update(this.state.recipientId, values).then((response) => {
        notification.info({ message: '修改成功' });
        this.fetchEmailRecipients();
      }).finally(() => {
        this.setState({ recipientLoading: false });
      });
    }
    this.hideEditRecipientModal();
  }
  handleRecipientModalOk = () => {
    this.recipientForm.current.submit();
    this.hideEditRecipientModal();
  }
  handleDeleteRecipient = (id) => {
    this.setState({ loading: true });
    GoodHoDayAPI.configs.email.recipient.destroy(id).then((response) => {
      console.log("handleDelete", id, response);
      notification.info({
        message: "刪除成功"
      });
    }).finally(() => {
      this.setState({ loading: false });
      this.fetchEmailRecipients();
    });
  }
  render() {
    const columns = [
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
        render: (text, record) => <a onClick={this.showEditRecipientModal.bind(this, record.id)}>{text}</a>
      },
      {
        title: '動作',
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Typography.Link onClick={this.showEditRecipientModal.bind(this, record.id)}>修改</Typography.Link>
            <Typography.Link onClick={this.deleteRecipientModel.current.confirmDelete.bind(this, record.id)}>刪除</Typography.Link>
          </Space>
        ),
      },
    ];

    return <div>
      <Breadcrumb />
      <h1>設定</h1>
      <Collapse defaultActiveKey={[1, 2]}>
        <Panel key={1} header="寄件信箱設定">
          <Form ref={this.form} onFinish={this.saveEmailSmtp} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <Form.Item label="寄件者名稱" name="from_name">
              <Input />
            </Form.Item>
            <Form.Item label="寄件信箱" name="from_address">
              <Input />
            </Form.Item>
            <Form.Item label="SMTP 伺服器" name="host">
              <Input />
            </Form.Item>
            <Form.Item label="SMTP 加密方式" name="encryption">
              <Select onChange={this.handleChangeEncryption}>
                <Select.Option value="">不加密</Select.Option>
                <Select.Option value="ssl">SSL</Select.Option>
                <Select.Option value="tls">TLS</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="SMTP Port" name="port">
              <InputNumber />
            </Form.Item>
            <Form.Item label="帳號" name="username">
              <Input />
            </Form.Item>
            <Form.Item label="密碼" name="password">
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
              <Button type="primary" htmlType="submit" loading={this.state.smtpLoading}>儲存</Button>
            </Form.Item>
          </Form>
        </Panel>
        <Panel key={2} header="收件信箱設定">
          <Button type="primary" onClick={this.showEditRecipientModal.bind(this, null)}>
            新增
          </Button>
          <Table columns={columns} dataSource={this.state.recipients} rowKey="id" scroll={{ x: 400 }} />
          <Modal
            title={`${this.state.recipientEditMode === 'store' ? "新增" : "修改"}收件者`}
            visible={this.state.showEditRecipientModal}
            onOk={this.handleRecipientModalOk}
            onCancel={this.hideEditRecipientModal}
            okText={this.state.recipientEditMode === 'store' ? "新增" : "修改"}
            cancelText="取消"
          >
            <Form ref={this.recipientForm} onFinish={this.handleEditRecipient}>
              <Form.Item label="Email" name="email">
                <Input />
              </Form.Item>
            </Form>
          </Modal>
          <DeleteModal ref={this.deleteRecipientModel} title="收件者" onDelete={this.handleDeleteRecipient} />
        </Panel>
      </Collapse>
    </div>;
  }
}

export default AdminSettingPage;
