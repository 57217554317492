import React from 'react'

export default function Marker({ name, label, onClick, selected, iconType, customData, ...options }) {
    const { map, infoWindow } = options;
    const [marker, setMarker] = React.useState();

    React.useEffect(() => {
        if (!marker) {
            window.google.maps.Marker.prototype.getCustomData = function (key) {
                if (typeof (this.custom_data) === 'undefined') {
                    this.custom_data = {};
                }
                if (typeof (key) !== 'undefined') {
                    return this.custom_data[key];
                }
                else {
                    return this.custom_data;
                }
            }
            window.google.maps.Marker.prototype.setCustomData = function (key, value) {
                if (typeof (this.custom_data) === 'undefined') {
                    this.custom_data = {};
                }

                if (typeof (value) !== 'undefined') {
                    this.custom_data[key] = value;
                }
                else {
                    this.custom_data = key;
                }
            }
            setMarker(new window.google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker, label]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    React.useEffect(() => {
        if (marker) {
            ["click"].forEach((eventName) =>
                window.google.maps.event.clearListeners(marker, eventName)
            );

            if (onClick) {
                marker.addListener("click", (e) => {
                    onClick(e);
                });
            }
        }
    }, [marker, onClick]);

    React.useEffect(() => {
        if (marker) {
            ["mouseover", "mouseout"].forEach((eventName) =>
                window.google.maps.event.clearListeners(marker, eventName)
            );
            if (selected) {
                marker.setIcon({
                    url: require('../../images/icons/marker-sel.png'),
                    labelOrigin: { x: 37, y: 37 }
                })
                if (marker.getAnimation() === null) {
                    marker.setAnimation(window.google.maps.Animation.DROP);
                }
                map.panTo(marker.getPosition());
                infoWindow.setContent(name);
                infoWindow.open(map, marker)
            } else {
                marker.setIcon({
                    url: require('../../images/icons/marker-' + iconType + '.png'),
                    labelOrigin: { x: 15, y: 15 }
                })
                if (marker.getAnimation() !== null) {
                    marker.setAnimation(null);
                }
                marker.addListener('mouseover', (e) => {
                    marker.setIcon({
                        url: require('../../images/icons/marker-hover.png'),
                        labelOrigin: { x: 15, y: 15 }
                    })
                });
                marker.addListener('mouseout', (e) => {
                    marker.setIcon({
                        url: require('../../images/icons/marker-' + iconType + '.png'),
                        labelOrigin: { x: 15, y: 15 }
                    })
                });
            }
        }
    }, [marker, map, infoWindow, name, selected, iconType]);

    React.useEffect(() => {
        if (marker && customData) {
            for (const [key, value] of Object.entries(customData)) {
                marker.setCustomData(key, value);
            }
        }
    }, [marker, customData]);

    React.useEffect(() => {
        if (marker) {
            if (selected) {
                marker.setLabel({ text: label, color: "white", fontSize: '24px', fontWeight: 'bold' });
            } else {
                marker.setLabel({ text: label, color: "white", fontSize: '9px', fontWeight: 'bold' });
            }
        }
    }, [marker, label, selected]);


    return null;
};