import { Col, Row, Table } from 'antd'
import React, { Component } from 'react'
import AnimatedNumber from "animated-number-react";
import Card from '../../../../components/Card'
import DashboardCard from '../../../../components/DashboardCard'
import GoodHoDayAPI from '../../../../api/GoodHoDayAPI';
import ResponsiveTable from '../../../../components/ResponsiveTable';

export class RegionStoreUsageDetail extends Component {
    state = {
        loading: false,
        result: {},
        detail: [],
    }
    componentDidMount() {
        this.refresh();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.storeid !== this.props.storeid) {
            this.refresh();
        }
        if (prevProps.query !== this.props.query) {
            this.refresh();
        }
    }
    refresh = async () => {
        const regionId = this.props.regionid;
        const storeId = this.props.storeid;
        const query = this.props.query;
        if (!regionId || !storeId) return;
        this.setState({ loading: true });
        //console.log("Fetch store data:", regionId, storeId)
        await GoodHoDayAPI.reports.store.region.store.show(regionId, storeId, query).then((response) => {
            this.setState({ result: response.data });
            //console.log("result", response.data);
        });
        await GoodHoDayAPI.reports.store.region.store.clerks.index(regionId, storeId, query).then((response) => {
            this.setState({ detail: response.data });
            //console.log("detail", response.data);
        })
        this.setState({ loading: false });
    }
    render() {
        const result = this.state.result;
        const columns = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                render: text => <a>{text}</a>,
            },
            // {
            //     title: '發放總數',
            //     dataIndex: 'issueCount',
            //     key: 'issueCount',
            // },
            // {
            //     title: '該店佔比',
            //     dataIndex: 'totalConsumeRate',
            //     key: 'totalIssueRate',
            //     render: (value) => (value * 100).toFixed(2) + ' %'
            // },
            {
                title: '核銷總數',
                dataIndex: 'consumeCount',
                key: 'consumeCount',
            },
            {
                title: '該店佔比',
                dataIndex: 'totalConsumeRate',
                key: 'totalConsumeRate',
                render: (value) => (value * 100).toFixed(2) + ' %'
            },
            // {
            //     title: '跨店核銷 (缺)',
            //     dataIndex: 'crossCount',
            //     key: 'crossCount',
            //     render: () => '-',
            // },
        ];
        return (
            <Row gutter={[10, 10]}>
                <Col span={24} xxl={12}>
                    <Card style={{ display: 'flex' }}>
                        <div style={{ flex: 2 }}>
                            <span style={{ color: '#A4A3A3', fontWeight: 700, fontSize: '1.1rem', lineHeight: '2.5rem' }}>{result.name} 已使用數量</span>
                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <span style={{ color: '#717171', fontWeight: 700, fontSize: '2.5rem', lineHeight: '2.5rem' }}>
                                <AnimatedNumber value={result.consumeCount} formatValue={(value) => value.toFixed(0)} />
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col span={24} xxl={12}>
                    <Card style={{ display: 'flex' }}>
                        <div style={{ flex: 2 }}>
                            <span style={{ color: '#A4A3A3', fontWeight: 700, fontSize: '1.1rem', lineHeight: '2.5rem' }}>本店使用率</span>
                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <span style={{ color: '#717171', fontWeight: 700, fontSize: '2.5rem', lineHeight: '2.5rem' }}>
                                <AnimatedNumber value={result.totalConsumeRate * 100} formatValue={(value) => value.toFixed(2)} />%
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <DashboardCard img={require('../../../../images/icons/store-self.png')} color="#EC5D9E" title="本店來源使用" rate={result.issueStoreConsumeCount} unit="" fixed={0} />
                </Col>
                <Col span={8}>
                    <DashboardCard img={require('../../../../images/icons/store-other.png')} color="#FFD954" title="他店來源使用" rate={result.otherStoreConsumeCount} unit="" fixed={0} />
                </Col>
                <Col span={8}>
                    <DashboardCard img={require('../../../../images/icons/store-cross.png')} color="#646464" title="跨店核銷" rate={result.crossStoreConsumeCount} unit="" fixed={0} />
                </Col>
                <Col span={24}>
                    <Card>
                        <h3>分店人員核銷狀況</h3>
                        <ResponsiveTable columns={columns} dataSource={this.state.detail} rowKey="id" />
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default RegionStoreUsageDetail