import { Button, Col, notification, Row, Space, Typography } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ResponsiveTable from '../../components/ResponsiveTable';
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import BrandSelect from '../../components/BrandSelect';
import DeleteModal from '../../components/DeleteModal';
import moment from "moment";
import Breadcrumb from '../../components/Breadcrumb';
export class AdminActivityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      brandId: null,
      activities: [],
      total: 0,
      pageSize: 10,
    }
    this.deleteModal = React.createRef();
  }
  componentDidMount() {
    this.refresh();
  }
  refresh = (page, pageSize) => {
    if (!this.state.brandId) return;
    this.setState({ loading: true });
    GoodHoDayAPI.activities.index({ brand_id: this.state.brandId }, page, pageSize).then((response) => {
      const result = response.data;
      this.setState({
        activities: result.data,
        total: result.total,
        pageSize: result.per_page,
      });
    }).finally(() => {
      this.setState({ loading: false });
    })
  }
  handleBrandChange = (brandId) => {
    //console.log("handleBrandChange", brandId);
    this.setState({ brandId: brandId }, () => {
      this.refresh();
    });
  }
  handleDelete = (id) => {
    this.setState({ loading: true });
    GoodHoDayAPI.activities.destroy(id).then((response) => {
      //console.log("handleDelete", id, response);
      notification.info({
        message: "刪除成功"
      });
    }).finally(() => {
      this.setState({ loading: false });
      this.refresh();
    });
  }
  render() {
    const columns = [
      {
        title: '活動名稱',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => <Link to={`/admin/activities/${record.id}`}>{text}</Link>,
      },
      {
        title: '開始日期',
        dataIndex: 'starttime',
        key: 'starttime',
        render: (text) => moment(text).format("YYYY-MM-DD"),
        width: 120,
      },
      {
        title: '結束日期',
        dataIndex: 'endtime',
        key: 'endtime',
        render: (text) => moment(text).format("YYYY-MM-DD"),
        width: 120,
      },
      {
        title: '優惠內容',
        key: 'description',
        dataIndex: 'description',
        ellipsis: true
      },
      {
        title: '動作',
        key: 'action',
        width: 120,
        render: (text, record) => (
          <Space size="middle">
            <Link to={`/admin/activities/${record.id}`}>修改</Link>
            <Typography.Link onClick={this.deleteModal.current.confirmDelete.bind(this, record.id)}>刪除</Typography.Link>
          </Space>
        ),
      },
    ];
    return <div>
      <Breadcrumb />
      <h1>活動上架</h1>
      <>
        <Row>
          <Col>
            <BrandSelect onChange={this.handleBrandChange} />
          </Col>
          <Col flex="auto" style={{ textAlign: 'right' }}>
            <Link to="/admin/activities/create">
              <Button type="primary" style={{ marginBottom: 16 }}>
                新增
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ResponsiveTable
              columns={columns}
              dataSource={this.state.activities}
              rowKey="id"
              pagination={{
                total: this.state.total,
                pageSize: this.state.pageSize,
                onChange: (page, pageSize) => this.refresh(page, pageSize),
              }}

            />
            <DeleteModal ref={this.deleteModal} title="活動" onDelete={this.handleDelete} />
          </Col>
        </Row>
      </>
    </div>;
  }
}

export default AdminActivityPage;
