import { Select } from 'antd'
import React, { Component } from 'react'
import GoodHoDayAPI from '../api/GoodHoDayAPI';

export class AuthStoreSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            stores: [],
            value: props.value
        };
    }
    componentDidMount() {
        this.refresh();
    }
    handleOnChange = (storeId) => {
        this.setState({ value: storeId });
        if (this.props.onChange) {
            const stores = this.state.stores;
            const store = stores.find(store => store.id === storeId);
            this.props.onChange(storeId, store);
        }
    }
    refresh = async () => {
        if (!GoodHoDayAPI.auth.isLogined()) {
            return;
        }
        const store_ids = GoodHoDayAPI.auth.getLoginData().store_ids;
        await GoodHoDayAPI.auth.stores({ brand_id: this.props.brandid }, 1, 999).then((response) => {
            const result = response.data;
            const stores = result.data;
            const filterStores = stores.filter(store => store_ids.indexOf(store.id) >= 0);
            const defaultStoreId = filterStores.length > 0 ? filterStores[0].id:null;
            this.setState({
                stores: filterStores,
            }, () => this.handleOnChange(this.state.value ?? defaultStoreId));
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    render() {
        return (
            <Select
                placeholder="門市"
                showSearch
                optionFilterProp="name"
                filterOption={(input, option) =>
                    option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ minWidth: 150, marginRight: 10 }}
                {...this.props}
                value={this.state.value}
                onChange={this.handleOnChange}>
                {this.state.stores.map((store) => <Select.Option key={store.id} value={store.id}>{store.name} ({store.id})</Select.Option>)}
            </Select>
        )
    }
}

export default AuthStoreSelect