import { Button, DatePicker, Form, Input, InputNumber, notification, Radio } from 'antd';
import React, { Component } from 'react'
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import BrandSelect from '../../components/BrandSelect';
import Breadcrumb from '../../components/Breadcrumb';
import withRouterParamsHook from '../../hoc/withRouterParamsHook';
import CopyToClipboard from 'react-copy-to-clipboard';
import "./AdminActivityEditPage.css";
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 4,
        span: 20,
    },
};
export class AdminActivityEditPage extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef();
        this.state = {
            loading: false,
            mode: 'store',
            activityId: null,
        };
    }
    componentDidMount() {
        console.log(this.props);
        const activityId = this.props.params.activityId;
        this.fetchData(activityId);
    }
    fetchData = (activityId) => {
        if (!activityId) return;
        GoodHoDayAPI.activities.show(activityId).then((response) => {
            const activityData = response.data;
            this.setState({
                mode: 'edit',
                activityId: activityId
            });
            this.form.current.setFieldsValue(activityData);
        })
    }
    handleFinish = (values) => {
        if (this.state.mode === 'store') {
            this.setState({ loading: true });
            GoodHoDayAPI.activities.store(values).then((response) => {
                notification.info({ message: '新增成功' });
                this.fetchData(response.data.id);
            }).finally(() => {
                this.setState({ loading: false });
            })
        } else {
            this.setState({ loading: true });
            GoodHoDayAPI.activities.update(this.state.activityId, values).then((response) => {
                notification.info({ message: '修改成功' });
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
    };
    handleReset = () => {
        this.form.current.resetFields();
    };
    disabledStartDate = (current) => {
        const endtime = this.form.current.getFieldValue('endtime');
        if (current && endtime) {
            return current.diff(endtime) > 0;
        }
        return false;
    }
    disabledEndDate = (current) => {
        const starttime = this.form.current.getFieldValue('starttime');
        if (current && starttime) {
            return current.diff(starttime) < 0;
        }
        return false;
    }
    getEmbedScript = (activityId) => {
        return `<script>
window.opsdkAsyncInit = function() {
    OPSDK.init({ activityId: ${activityId} });
};
</script>
<script async defer crossorigin="anonymous" src="${process.env.REACT_APP_URL}/sdk/OmohoPlusSDK.js"></script>`
    }
    render() {
        return (
            <div>
                <Breadcrumb />
                <h1>{this.state.mode === 'store' ? '新增' : '修改'}活動</h1>
                <Form {...layout} ref={this.form} initialValues={{ maximum: 100, usetimeslimit: 1, giveawayenabled: 0 }} onFinish={this.handleFinish} onReset={this.handleReset}>
                    <Form.Item label="品牌" name="brand_id">
                        <BrandSelect />
                    </Form.Item>
                    <Form.Item label="活動名稱" name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label="活動起/訖" style={{ marginBottom: 0 }}>
                        <Form.Item name="starttime" style={{ display: 'inline-block', marginRight: 10 }}>
                            <DatePicker disabledDate={this.disabledStartDate} placeholder="開始日期" />
                        </Form.Item>
                        <Form.Item name="endtime" style={{ display: 'inline-block' }}>
                            <DatePicker disabledDate={this.disabledEndDate} placeholder="結束日期" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="優惠內容" name="description">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item label="優惠品項" name="item">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item label="優惠碼設定" name="code">
                        <Input placeholder="AZUQ" />
                    </Form.Item>
                    <Form.Item label="發放上限" name="maximum">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="使用次數" name="usetimeslimit">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="優惠類型" name="giveawayenabled">
                        <Radio.Group>
                            <Radio value={0}>只有優惠碼</Radio>
                            <Radio value={1}>來店碼+優惠碼</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} style={{ marginRight: 10 }}>
                            送出
                        </Button>
                        <Button htmlType="reset">
                            重設
                        </Button>
                    </Form.Item>
                </Form>
                <h2>活動追蹤</h2>
                <p>這是此活動追蹤程式碼。請複製這段程式碼，並在您想追蹤的每個網頁上，於 &lt;HEAD&gt; 中當作第一個項目貼上。</p>
                {this.state.activityId ?
                    <div style={{ height: 150, position: 'relative' }}>
                        <div style={{ position: 'absolute', top: 15, right: 15 }}>
                            <CopyToClipboard
                                text={this.getEmbedScript(this.state.activityId)}
                                onCopy={() => notification.info({ message: '複製成功' })}>
                                <Button>複製到剪貼簿</Button>
                            </CopyToClipboard>
                        </div>
                        <textarea value={this.getEmbedScript(this.state.activityId)} className="codeTxtBox"></textarea>
                    </div> : <div className="codeTxtBox">您需要先新增活動才能使用活動追蹤程式碼</div>
                }
            </div>
        )
    }
}

export default withRouterParamsHook(AdminActivityEditPage)