export default function getGPSCoordByName(name) {
    let coord = null;
    switch (name) {
        default:
        case '台北市':
            coord = { lat: 25.0478, lng: 121.5319, zoom: 12 };
            break;
        case '新北市':
            coord = { lat: 25.0143, lng: 121.4672, zoom: 10 };
            break;
        case '桃園市':
            coord = { lat: 24.9889, lng: 121.3111, zoom: 12 };
            break;
        case '台中市':
            coord = { lat: 24.1500, lng: 120.6667, zoom: 12 };
            break;
        case '台南市':
            coord = { lat: 22.9833, lng: 120.1833, zoom: 11 };
            break;
        case '高雄市':
            coord = { lat: 22.6167, lng: 120.3000, zoom: 12 };
            break;
        case '新竹縣':
            coord = { lat: 24.8333, lng: 121.0000, zoom: 11 };
            break;
        case '苗栗縣':
            coord = { lat: 24.5700, lng: 120.8200, zoom: 11 };
            break;
        case '彰化縣':
            coord = { lat: 24.0667, lng: 120.5333, zoom: 11 };
            break;
        case '南投縣':
            coord = { lat: 23.9167, lng: 120.6833, zoom: 10 };
            break;
        case '雲林縣':
            coord = { lat: 23.7075, lng: 120.5439, zoom: 11 };
            break;
        case '嘉義縣':
            coord = { lat: 23.4500, lng: 120.3333, zoom: 11 };
            break;
        case '屏東縣':
            coord = { lat: 22.6761, lng: 120.4942, zoom: 10 };
            break;
        case '宜蘭縣':
            coord = { lat: 24.7500, lng: 121.7500, zoom: 9 };
            break;
        case '花蓮縣':
            coord = { lat: 23.9722, lng: 121.6064, zoom: 9 };
            break;
        case '台東縣':
            coord = { lat: 22.7583, lng: 121.1444, zoom: 9 };
            break;
        case '澎湖縣':
            coord = { lat: 23.5667, lng: 119.5833, zoom: 10 };
            break;
        case '金門縣':
            coord = { lat: 24.4167, lng: 118.3167, zoom: 10 };
            break;
        case '連江縣':
            coord = { lat: 26.1598, lng: 119.9432, zoom: 11 };
            break;
        case '基隆市':
            coord = { lat: 25.1283, lng: 121.7419, zoom: 12 };
            break;
        case '新竹市':
            coord = { lat: 24.8047, lng: 120.9714, zoom: 13 };
            break;
        case '嘉義市':
            coord = { lat: 23.4800, lng: 120.4497, zoom: 13 };
            break;
    }
    return coord;
}