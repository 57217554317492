import React, { Component } from 'react';
import { Button, Col, notification, Row, Space, Tag, Typography } from 'antd';
import ResponsiveTable from '../../components/ResponsiveTable';
import { Link } from 'react-router-dom';
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import moment from "moment";
import DeleteModal from '../../components/DeleteModal';
import BrandSelect from '../../components/BrandSelect';
import StoreSelect from '../../components/StoreSelect';
import Breadcrumb from '../../components/Breadcrumb';

export class AdminUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      total: 0,
      pageSize: 10,
      brandId: null,
      storeId: null,
    };
    this.deleteModal = React.createRef();
  }
  componentDidMount() {
    this.refresh();
  }
  refresh = (page, pageSize) => {
    GoodHoDayAPI.users.index({
      brand_id: this.state.brandId,
      store_id: this.state.storeId
    }, page, pageSize).then((response) => {
      const result = response.data;
      this.setState({
        users: result.data,
        total: result.total,
        pageSize: result.per_page,
      })
    })
  }
  handleDelete = (id) => {
    this.setState({ loading: true });
    GoodHoDayAPI.users.destroy(id).then((response) => {
      //console.log("handleDelete", id, response);
      notification.info({
        message: "刪除成功"
      });
    }).finally(() => {
      this.setState({ loading: false });
      this.refresh();
    });
  }
  handleBrandChange = (value) => {
    //console.log("handleBrandChange", value);
    this.setState({
      brandId: value,
      storeId: null
    }, () => this.refresh());
  }
  handleStoreChange = (value) => {
    //console.log("handleStoreChange", value);
    this.setState({
      storeId: value
    }, () => this.refresh());
  }
  render() {
    const columns = [
      {
        title: '名稱',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        render: (text, record) => <Link to={`/admin/users/${record.id}`}>{text}</Link>
        ,
      },
      {
        title: '帳號',
        dataIndex: 'email',
        key: 'email',
        width: 400,
      },
      {
        title: '建立時間',
        key: 'created_at',
        dataIndex: 'created_at',
        render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss")
      },
      {
        title: '身份',
        dataIndex: 'roles',
        key: 'roles',
        render: tags => (
          <>
            {tags.map((tag, index) => {
              let color = 'volcano';
              switch (tag) {
                default:
                case 'clerk': tag = '店員'; color = 'geekblue'; break;
                case 'admin': tag = '管理者'; color = 'green'; break;
                case 'brand_manager': tag = '品牌管理者'; color = 'volcano'; break;
                case 'store_main_manager': tag = '店長'; color = 'volcano'; break;
              }
              return (
                <Tag color={color} key={index}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: '備註',
        key: 'comment',
        dataIndex: 'comment',
        ellipsis: true,
      },
      {
        title: '動作',
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Link to={`/admin/users/${record.id}`}>修改</Link>
            <Typography.Link onClick={this.deleteModal.current.confirmDelete.bind(this, record.id)}>刪除</Typography.Link>
          </Space>
        ),
      },
    ];

    return <div>
      <Breadcrumb />
      <h1>帳號管理</h1>
      <Row>
        <Col>
          <BrandSelect onChange={this.handleBrandChange} allowClear={true} />
          <StoreSelect brandid={this.state.brandId} value={this.state.storeId} onChange={this.handleStoreChange} allowClear={true} />
        </Col>
        <Col flex="auto" style={{ textAlign: 'right' }}>
          <Link to="/admin/users/create">
            <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
              新增
            </Button>
          </Link>
        </Col>
      </Row>


      <ResponsiveTable
        columns={columns}
        dataSource={this.state.users}
        rowKey="id"
        pagination={{
          total: this.state.total,
          pageSize: this.state.pageSize,
          onChange: (page, pageSize) => this.refresh(page, pageSize)
        }}
      />
      <DeleteModal ref={this.deleteModal} title="使用者" onDelete={this.handleDelete} />

    </div>;
  }
}

export default AdminUserPage;
