import { Breadcrumb } from 'antd';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import withRouterLocationHook from '../hoc/withRouterLocationHook';


const breadcrumbNameMap = {
    '/admin': '首頁',
    '/admin/stores': '門市管理',
    '/admin/stores/create': '新增',
    '/admin/stores/:num': '編輯',
    '/admin/activities': '活動上架',
    '/admin/activities/create': '新增',
    '/admin/activities/:num': '編輯',
    '/admin/coupons': '優惠券管理',
    '/admin/customers': '顧客名單管理',
    '/admin/reports': '績效管理',
    '/admin/reports/store': '門市報表',
    '/admin/reports/customer': '顧客行為報表',
    '/admin/reports/activity': '活動數據報表',
    '/admin/users': '帳號管理',
    '/admin/users/create': '新增',
    '/admin/users/:num': '編輯',
    '/admin/settings': '設定管理',
    '/admin/store': '門市',
    '/admin/store/profile': '門市資料',
    '/admin/store/coupons': '門市優惠券追蹤',
    '/admin/store/customers': '門市顧客名單',
    '/admin/store/scan': '掃描頁面',
};
export class BreadcrumbWithReactRouterDom extends Component {
    render() {
        const location = this.props.location;
        const pathSnippets = location.pathname.split('/').filter(i => i);
        //console.log(pathSnippets);
        const extraBreadcrumbItems = pathSnippets.map((_, index) => {
            const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
            //console.log(_, index, url);
            return (
                <Breadcrumb.Item key={url}>
                    <Link to={url}>{breadcrumbNameMap[url]}</Link>
                </Breadcrumb.Item>
            );
        });
        //console.log(extraBreadcrumbItems);
        // const breadcrumbItems = [
        //     <Breadcrumb.Item key="home">
        //         <Link to="/">登入</Link>
        //     </Breadcrumb.Item>,
        // ].concat(extraBreadcrumbItems);
        return (
            <Breadcrumb style={{ marginBottom: 15 }}>{extraBreadcrumbItems}</Breadcrumb>
        )
    }
}

export default withRouterLocationHook(BreadcrumbWithReactRouterDom)