import { Button, Col, notification, Row, Space, Table, Typography } from 'antd'
import React, { Component } from 'react'
import GoodHoDayAPI from '../api/GoodHoDayAPI';
import DeleteModal from './DeleteModal';
import StoreClerkEditModal from './StoreClerkEditModal';

export class StoreClerkTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            clerks: [],
            showStoreContactEdit: false,
            editClerkId: null
        };
        this.deleteModal = React.createRef();
    }
    componentDidMount() {
        this.refresh();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.storeid !== this.props.storeid) {
            this.refresh();
        }
    }
    refresh = () => {
        this.setState({ loading: true });
        GoodHoDayAPI.stores.clerks.index(this.props.storeid, 1, 999).then((response) => {
            const result = response.data;
            this.setState({
                clerks: result.data
            });
        }).finally(() => {
            this.setState({ loading: false });
        })
    }
    handleDelete = (id) => {
        this.setState({ loading: true });
        GoodHoDayAPI.stores.clerks.destroy(this.props.storeid, id).then((response) => {
            console.log("handleDelete", id, response);
            notification.info({
                message: "刪除成功"
            });
        }).finally(() => {
            this.setState({ loading: false });
            this.refresh();
        });
    }
    showStoreClerkEditModal = (clerkId) => {
        console.log("showStoreClerkEditModal", clerkId);
        this.setState({
            showStoreClerkEdit: true,
            editClerkId: clerkId
        })
    }
    hideStoreClerkEditModal = () => {
        this.setState({
            showStoreClerkEdit: false,
            editClerkId: null
        });
        this.refresh();
    }
    render() {
        const columns = [
            {
                title: '職稱',
                dataIndex: 'title',
                key: 'title',
                render: (text) => {
                    switch (text) {
                        case 'mainmanager': return "店長";
                        case 'manager': return "店經理";
                        default:
                        case 'clerk': return "店員";
                    }
                }
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                render: text => text,
            },
            {
                title: '電話',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
                render: (text, record) => record.user.email,
            },
            // {
            //     title: '權限',
            //     dataIndex: 'role',
            //     key: 'role',
            //     render: text => text,
            // },
            {
                title: '動作',
                key: 'action',
                width: 150,
                render: (text, record) => (
                    <Space size="middle">
                        <Typography.Link onClick={this.showStoreClerkEditModal.bind(this, record.id)}>修改</Typography.Link>
                        <Typography.Link onClick={this.deleteModal.current.confirmDelete.bind(this, record.id)}>刪除</Typography.Link>
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Row style={{ marginBottom: 10 }}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={this.showStoreClerkEditModal.bind(this, null)}>新增</Button>
                    </Col>
                </Row>
                <Table columns={columns} dataSource={this.state.clerks} rowKey="id" scroll={{ x: 1024 }} />
                <StoreClerkEditModal
                    visible={this.state.showStoreClerkEdit}
                    storeid={this.props.storeid}
                    clerkid={this.state.editClerkId}
                    onOk={this.hideStoreClerkEditModal}
                    onCancel={this.hideStoreClerkEditModal} />
                <DeleteModal ref={this.deleteModal} title="聯絡人" onDelete={this.handleDelete} />
            </div>
        )
    }
}

export default StoreClerkTable