import { notification } from "antd";
import axios from "axios";
import moment from "moment";
import PermissionManager from "../PermissionManager";

axios.defaults.baseURL = 'http://localhost:8091/api';
if (process.env.REACT_APP_URL) {
    axios.defaults.baseURL = process.env.REACT_APP_URL + '/api';
}

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    if (error.response && error.response.data) {
        const content = error.response.data;
        if (content.message) {
            console.log("error has message", content.message);
            notification.error({
                title: '錯誤',
                message: content.message
            });
        }
        if (content.details) {
            const details = content.details;
            console.log("error has details", content.details);
            if (Array.isArray(details)) {
                details.forEach((message) => {
                    notification.error({
                        title: '錯誤明細',
                        message: message
                    });
                })
            }
        }
        if (content.validation) {
            let errorFields = content.validation;
            console.log("error has validation", content.validation);
            for (let fieldId in errorFields) {
                const errors = errorFields[fieldId];
                if (!Array.isArray(errors)) continue;
                errors.forEach((message) => {
                    notification.error({
                        title: '驗証錯誤',
                        message: message
                    });
                });
            }
        }
    }
    return Promise.reject(error);
});

axios.interceptors.request.use(async function (config) {
    return await GoodHoDayAPI.auth.checkToken(config);
});

const defaultPageSize = 10;
export default class GoodHoDayAPI {
    /**
     * 將 Object 轉為 FormData
     * @param {*} data 
     * @returns FormData 
     */
    static toFormData = (data) => {
        var formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key] !== undefined ? data[key] : '');
        }
        return formData;
    }
    /**
     * 品牌
     */
    static brands = class {
        static index = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/brands`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    ...query
                },
            });
        };

        static show = (id) => axios.get(`/brands/${id}`);
        static store = (data) => {
            return axios.post(`/brands`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/brands/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/brands/${id}`);

        static users = class {
            static index = (storeId, page, pageSize) => {
                return axios.get(`/brands/${storeId}/users`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };

            static show = (storeId, id) => axios.get(`/brands/${storeId}/users/${id}`);
            static store = (storeId, data) => {
                return axios.post(`/brands/${storeId}/users`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
            }
            static update = (storeId, id, data) => {
                return axios.patch(`/brands/${storeId}/users/${id}`, data);
            }
            static destroy = (storeId, id) => axios.delete(`/brands/${storeId}/users/${id}`);
        }
    }
    /**
     * 店家
     */
    static stores = class {
        static index = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/stores`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    ...query
                },
            });
        };

        static show = (id) => axios.get(`/stores/${id}`);
        static store = (data) => {
            return axios.post(`/stores`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/stores/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/stores/${id}`);

        static import = (brand_id, file) => {
            const formData = GoodHoDayAPI.toFormData({ brand_id: brand_id, stores: file })
            return axios.post(`/stores/import`, formData);
        }

        static clerks = class {
            static index = (storeId, page, pageSize) => {
                return axios.get(`/stores/${storeId}/clerks`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };

            static show = (storeId, id) => axios.get(`/stores/${storeId}/clerks/${id}`);
            static store = (storeId, data) => {
                return axios.post(`/stores/${storeId}/clerks`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
            }
            static update = (storeId, id, data) => {
                return axios.patch(`/stores/${storeId}/clerks/${id}`, data);
            }
            static destroy = (storeId, id) => axios.delete(`/stores/${storeId}/clerks/${id}`);
        }
    }
    /**
     * 活動
     */
    static activities = class {
        static index = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/activities`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    ...query
                },
            });
        };

        static show = (id) => axios.get(`/activities/${id}`).then((response) => {
            response.data.starttime = moment(response.data.starttime);
            response.data.endtime = moment(response.data.endtime);
            return response
        });
        static store = (data) => {
            return axios.post(`/activities`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/activities/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/activities/${id}`);


        static coupons = class {
            static index = (activityId, query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/activities/${activityId}/coupons`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        ...query

                    },
                });
            };

            static show = (activityId, id) => axios.get(`/activities/${activityId}/coupons/${id}`);
            static store = (activityId, data) => {
                return axios.post(`/activities/${activityId}/coupons`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
            }
            static update = (activityId, id, data) => {
                return axios.patch(`/activities/${activityId}/coupons/${id}`, data);
            }
            static destroy = (activityId, id) => axios.delete(`/activities/${activityId}/coupons/${id}`);

            static export = (activityId) => axios.get(`/activities/${activityId}/coupons/export`, {
                responseType: 'blob',
            });
        }
    };
    /**
     * 顧客
     */
    static customers = class {
        static index = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/customers`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    ...query,
                },
            });
        };

        static show = (id) => axios.get(`/customers/${id}`);
        static store = (data) => {
            return axios.post(`/customers`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/customers/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/customers/${id}`);
    }
    /**
     * 地區
     */
    static regions = class {
        static index = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/regions`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    ...query
                },
            });
        };

        static show = (id) => axios.get(`/regions/${id}`);
        static store = (data) => {
            return axios.post(`/regions`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/regions/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/regions/${id}`);
    }
    /**
     * 使用者
     */
    static users = class {
        static index = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/users`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    ...query
                },
            });
        };

        static show = (id) => axios.get(`/users/${id}`);
        static store = (data) => {
            return axios.post(`/users`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/users/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/users/${id}`);
    }
    /**
     * 報表
     */
    static reports = class {
        static dashboard = class {
            static stat = (query) => axios.get('/report/dashboard/coupon-stat', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
        }
        static customer = class {
            static consumeTime = (query) => axios.get('/report/customer/consume-time', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static couponIssue = (query) => axios.get('/report/customer/coupon-issue', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static couponIssueTime = (query) => axios.get('/report/customer/week-coupon-issue', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            })
            static referrer = (query) => axios.get('/report/customer/referrer', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static regionByNearestStore = (query) => axios.get('/report/customer/region?type=nearest', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static regionByRedeemStore = (query) => axios.get('/report/customer/region?type=redeem', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static customerType = (query) => axios.get('/report/customer/customer-type', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static regionStat = (query) => axios.get('/report/store/regions', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            })
        }
        static store = class {
            static coupon = (query) => axios.get('/report/store/coupon', {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static region = class {
                static index = (query) => axios.get(`/report/store/regions`, {
                    params: {
                        // brand_id: 1,
                        // activity_id: 1,
                        // startdate: '2022-02-15',
                        // enddate: '2022-02-17',
                        ...query
                    },
                });
                static show = (regionId, query) => axios.get(`/report/store/regions/${regionId}`, {
                    params: {
                        // brand_id: 1,
                        // activity_id: 1,
                        // startdate: '2022-02-15',
                        // enddate: '2022-02-17',
                        ...query
                    },
                });
                static store = class {
                    static index = (regionId, query) => axios.get(`/report/store/regions/${regionId}/stores`, {
                        params: {
                            // brand_id: 1,
                            // activity_id: 1,
                            // startdate: '2022-02-15',
                            // enddate: '2022-02-17',
                            ...query
                        },
                    });

                    static show = (regionId, id, query) => axios.get(`/report/store/regions/${regionId}/stores/${id}`, {
                        params: {
                            // brand_id: 1,
                            // activity_id: 1,
                            // startdate: '2022-02-15',
                            // enddate: '2022-02-17',
                            ...query
                        },
                    });

                    static clerks = class {
                        static index = (regionId, storeId, query) => axios.get(`/report/store/regions/${regionId}/stores/${storeId}/clerks`, {
                            params: {
                                // brand_id: 1,
                                // activity_id: 1,
                                // startdate: '2022-02-15',
                                // enddate: '2022-02-17',
                                ...query
                            },
                        });
                    }
                }


            }
        }
        static activity = class {
            static discount = (query) => axios.get(`/report/activity/discount`, {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static giveaway = (query) => axios.get(`/report/activity/giveaway`, {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static total = (query) => axios.get(`/report/activity/coupon-issue-daily`, {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
            static stage = (query) => axios.get(`/report/activity/stage`, {
                params: {
                    // brand_id: 1,
                    // activity_id: 1,
                    // startdate: '2022-02-15',
                    // enddate: '2022-02-17',
                    ...query
                },
            });
        }

    }
    /**
     * 優惠券
     */
    static coupons = class {
        static index = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/coupons`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    ...query,
                },
            });
        };

        static show = (id) => axios.get(`/coupons/${id}`);
        static store = (data) => {
            return axios.post(`/coupons`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/coupons/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/coupons/${id}`);

        static showByCode = (storeId, couponCode) => axios.get(`/coupons/${couponCode}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: { store_id: storeId }
        });
        static redeemByCode = (storeId, couponCode, data) => axios.post(`/coupons`, JSON.stringify({
            store_id: storeId,
            couponcode: couponCode,
            ...data
        }), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    /**
     * 認證
     */
    static auth = class {
        _isLogined = false;
        _isRefreshingToken = false;
        _loginedUser = {};
        _saveToken = false; // 是否儲存 token 到 localstorage 給下次使用
        _tokenData = {
            user: null,
            expiresIn: null,
            expiresDate: null,
            token: null,
        };

        static isLogined() {
            //console.log("isLogined", this._isLogined);
            return this._isLogined;
        }
        static getLoginData() {
            return this._loginedUser;
        }
        static isNearExpired() {
            if (!this._tokenData) return;
            const expire = this._tokenData.expiresDate;
            const now = moment().valueOf();
            const nearExpire = expire - 1000 * (this._tokenData.expiresIn * 0.5); // 超過一半的有效期時自動 refresh
            if (now > nearExpire) {
                console.log(
                    "isNearExpired: ",
                    now > nearExpire,
                    "Now Date: ",
                    moment(now).format("YYYY-MM-DD HH:mm:ss"),
                    "Access Token Expire Date:",
                    moment(nearExpire).format("YYYY-MM-DD HH:mm:ss")
                );
            }
            return now > nearExpire;
        }
        static async checkToken(config) {
            if (this.isLogined() && this.isNearExpired()) {
                if (!this._isRefreshingToken) {
                    // If not start refreshing then start refreshing token.
                    await this.refresh();
                } else {
                    // Wait for other request refreshing token.
                    do {
                        console.log("Token is refreshing, waiting...", this._isRefreshingToken);
                        await new Promise((resolve) => setTimeout(() => resolve(true), 150));
                    } while (this._isRefreshingToken);
                }
                const newToken = this._tokenData.token;
                // Replace current request token to new one.
                if (newToken) {
                    console.log('Got new token', newToken);
                    config.headers['Authorization'] = `Bearer ${newToken}`;
                }
            }
            return config;
        }
        static clearAuthToken() {
            if (this._tokenData) {
                this._tokenData = {
                    user: null,
                    expiresIn: null,
                    expiresDate: null,
                    token: null,
                };
            }
            localStorage.removeItem('authUser');
            localStorage.removeItem('authToken');
            localStorage.removeItem('authTimeout');
            this._isLogined = false;
        }
        static saveToken(email, token, expires_in) {
            const expiresDate = new Date().getTime() + 1000 * expires_in;
            console.log("Save new token: ", email, expires_in, new Date(expiresDate));
            this._tokenData = {
                user: email,
                expiresIn: expires_in,
                expiresDate: expiresDate,
                token: token,
            };
            if (this._saveToken) {
                localStorage.setItem('authUser', email);
                localStorage.setItem('authToken', token);
                localStorage.setItem('authTimeout', expiresDate);
            }
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        }
        static loginWithToken = async () => {
            console.log("loginWithToken");
            try {
                const authUser = localStorage.getItem('authUser');
                const authToken = localStorage.getItem('authToken');
                const authTimeout = localStorage.getItem('authTimeout');
                if (!authTimeout || !authToken || !authTimeout) {
                    this.clearAuthToken();
                    console.log("Login with saved token failed: no token saved.");
                    return false;
                }
                if (new Date().getTime() > authTimeout) {
                    this.clearAuthToken();
                    return false;
                }
                axios.defaults.headers.common = { 'Authorization': `Bearer ${authToken}` }
                if (!await this.me(authUser)) {
                    console.log("Login with saved token failed: user not valid.", authUser);
                    this.clearAuthToken();
                    this.logout();
                    return false;
                }
                this._saveToken = true;
                this._isLogined = true;
                return true;
            } catch (error) {
                this.clearAuthToken();
                // 若為網路錯誤不會有 response 則繼續跳例外讓前端可以顥示錯誤訊息
                if (!error.response) {
                    throw error;
                }
            }
            return false;
        }
        static login = (email, password, rememberLogin, type, brandId) => {
            this.clearAuthToken();
            return axios.post('/auth/login', {
                email: email,
                password: password,
                type: type,
                brand_id: brandId
            }).then(async (response) => {
                try {
                    const data = response.data;
                    if (data.access_token) {
                        this._saveToken = rememberLogin;
                        this.saveToken(email, data.access_token, data.expires_in);
                        if (!await this.me(email)) {
                            this.logout();
                            return false;
                        }
                        this._isLogined = true;
                        return true;
                    }
                } catch (error) {
                    // 若為網路錯誤不會有 response 則繼續跳例外讓前端可以顥示錯誤訊息
                    if (!error.response) {
                        throw error;
                    }
                }
                return false;

            });
        }
        static logout = async () => axios.post('/auth/logout').then((response) => {
            //console.log("logout", response);
            axios.defaults.headers.common["Authorization"] = null;
            this.clearAuthToken();
            this._isLogined = false;
            return true;
        });
        static refresh = async () => {
            if (this._isRefreshingToken) {
                console.log("token is refreshing, skipped")
                return;
            }
            // Skip refresh token if there is no exist token
            if (!this._tokenData.token) return;

            console.log("Refreshing token...");
            this._isRefreshingToken = true;

            // Create new request to refresh token to avoid checkToken process recursive.
            return axios.create().post('/auth/refresh', {}, {
                headers: { Authorization: `Bearer ${this._tokenData.token}` }
            }).then((response) => {
                const data = response.data;
                if (data.access_token) {
                    const authUser = this._tokenData.user;
                    this.saveToken(authUser, data.access_token, data.expires_in);
                }
                return response;
            }).catch((error) => {
                notification.error({ message: "連線階段逾時，請重新登入" });
            }).finally(() => {
                this._isRefreshingToken = false;
            });
        };
        static me = () => axios.get('/auth/me').then((response) => {
            this._loginedUser = response.data;
            const roles = this._loginedUser ? this._loginedUser.roles : [];
            PermissionManager.updateRoles(roles);
            return response;
        });
        static brands = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/auth/brands`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    ...query
                },
            });
        };
        static stores = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/auth/stores`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    ...query
                },
            });
        };
    }
    /**
     * 設定
     */
    static configs = class {
        static email = class {
            static smtp = class {
                static show = () => axios.get(`/configs/email/smtp`);
                static store = (data) => {
                    return axios.post(`/configs/email/smtp`, JSON.stringify(data), {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });
                }
            }
            static recipient = class {
                static index = (query, page, pageSize) => {
                    if (!query) query = {};
                    return axios.get(`/configs/email/recipients`, {
                        params: {
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            ...query,
                        },
                    });
                };

                static show = (id) => axios.get(`/configs/email/recipients/${id}`);
                static store = (data) => {
                    return axios.post(`/configs/email/recipients`, JSON.stringify(data), {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });
                }
                static update = (id, data) => {
                    return axios.patch(`/configs/email/recipients/${id}`, data);
                }
                static destroy = (id) => axios.delete(`/configs/email/recipients/${id}`);
            }
        }
    }
}
