import { Button, Form, Input } from 'antd'
import React, { Component } from 'react'
const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};
export class StoreUserEditPage extends Component {
    render() {
        return (
            <div>
                <h1>新增/修改門市帳號</h1>
                <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                    <Form.Item label="名稱">
                        <Input />
                    </Form.Item>
                    <Form.Item label="帳號">
                        <Input />
                    </Form.Item>
                    <Form.Item label="密碼">
                        <Input />
                    </Form.Item>
                    <Form.Item label="備註">
                        <Input />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            送出
                        </Button>
                        <Button htmlType="button">
                            重設
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

export default StoreUserEditPage