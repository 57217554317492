import React, { Component } from 'react';
import { Button, Col, message, Modal, notification, Row, Space, Spin, Tag, Typography, Upload } from 'antd';
import ResponsiveTable from '../../components/ResponsiveTable';
import { Link } from 'react-router-dom';
import BrandSelect from '../../components/BrandSelect';
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import DeleteModal from '../../components/DeleteModal';
import { InboxOutlined } from '@ant-design/icons';
import Breadcrumb from '../../components/Breadcrumb';

export class AdminStorePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            brandId: null,
            stores: [],
            total: null,
            pageSize: 10,
            showBatchAddModal: false,
            fileList: [],
            uploading: false,
        }
        this.deleteModal = React.createRef();
    }
    componentDidMount() {
        this.refresh();
    }
    refresh = (page, pageSize) => {
        if (!this.state.brandId) return;
        this.setState({ loading: true });
        if (!this.state.brandId) return;
        GoodHoDayAPI.stores.index({ brand_id: this.state.brandId }, page, pageSize).then((response) => {
            const result = response.data;
            this.setState({
                stores: result.data,
                total: result.total,
                pageSize: result.perPage
            });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    handleBrandChange = (brandId) => {
        this.setState({ brandId: brandId }, () => {
            this.refresh();
        });
    }
    handleDelete = (id) => {
        this.setState({ loading: true });
        GoodHoDayAPI.stores.destroy(id).then((response) => {
            console.log("handleDelete", id, response);
            notification.info({
                message: "刪除成功"
            });
        }).finally(() => {
            this.setState({ loading: false });
            this.refresh();
        });
    }
    showBatchAddModal = () => {
        this.setState({ showBatchAddModal: true });
    }
    hideBatchAddModal = () => {
        this.setState({ showBatchAddModal: false, fileList: [] });
    }
    handleBatchAddFileChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }
    handleBatchAddFileDrop = (e) => {
        console.log('Dropped files', e.dataTransfer.files);
    }
    handleBatchAddFileBeforeUpload = (file) => {
        const contentType = file.type;
        let notAllowUpload = false;
        switch (contentType) {
            //case 'text/csv':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                notAllowUpload = true; break;
            default: notAllowUpload = false; break;

        }
        if (!notAllowUpload) {
            notification.error({ message: '無法上傳 csv 以外的檔案格式: ' + contentType });
            return Upload.LIST_IGNORE;
        }
        this.setState(state => ({
            //Only allow upload one file
            //fileList: [...state.fileList, file],
            fileList: [file],
        }));
        return false;
    }
    handleBatchAddFileOnRemove = (file) => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    }
    handleBatchAddFileUpload = () => {
        if (this.state.fileList.length <= 0) {
            notification.info({ message: '請先選擇要上傳的檔案' });
            return;
        }
        const file = this.state.fileList[0];
        this.setState({
            uploading: true,
        });
        GoodHoDayAPI.stores.import(this.state.brandId, file).then((response) => {
            notification.info({ message: '上傳成功' });
        }).finally(() => {
            this.setState({
                uploading: false,
            });
        });
    }
    render() {
        const columns = [
            {
                title: '門市名稱',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => <Link to={`/admin/stores/${record.id}`}>{text}</Link>,
                width: 150
            },
            {
                title: '營業電話',
                dataIndex: 'tel',
                key: 'tel',
                width: 150,
                render: (tel) => tel ? <a rel="noreferrer" href={`tel:${tel}`}>連結</a> : '-',
            },
            {
                title: '門市粉專',
                dataIndex: 'facebookurl',
                key: 'facebookurl',
                render: text => text ? <a target="_blank" rel="noreferrer" href={text}>連結</a> : '-',
                width: 150
            },
            {
                title: '營業時間',
                dataIndex: 'opentime',
                key: 'opentime',
                width: 150,
                ellipsis: true
            },
            {
                title: '門市 Email',
                key: 'email',
                dataIndex: 'email',
                width: 200,
                ellipsis: true
            },
            {
                title: '店長姓名',
                key: 'tags',
                dataIndex: 'tags',
                width: 150,
                render: (text, record) => (
                    <>
                        {record.main_managers.map(clerk => {
                            return (
                                <Tag color="volcano" key={clerk.id}>
                                    {clerk.name}
                                </Tag>
                            );
                        })}
                    </>
                ),
            },
            {
                title: '店經理姓名',
                key: 'tags',
                dataIndex: 'tags',
                width: 150,
                render: (text, record) => (
                    <>
                        {record.managers.map(clerk => {
                            return (
                                <Tag color="geekblue" key={clerk.id}>
                                    {clerk.name}
                                </Tag>
                            );
                        })}
                    </>
                ),
            },
            {
                title: '動作',
                key: 'action',
                width: 150,
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={`/admin/stores/${record.id}`}>修改</Link>
                        <Typography.Link onClick={this.deleteModal.current.confirmDelete.bind(this, record.id)}>刪除</Typography.Link>
                    </Space>
                ),
            },
        ];

        return <div>
            <Breadcrumb />
            <h1>門市管理</h1>
            <Row>
                <Col>
                    <BrandSelect onChange={this.handleBrandChange} />
                </Col>
                <Col flex="auto" style={{ textAlign: 'right' }} >
                    <Link to="/admin/stores/create">
                        <Button type="primary" style={{ marginBottom: 16, marginRight: 10 }}>
                            新增
                        </Button>
                    </Link>
                    <Button onClick={this.showBatchAddModal} type="primary" style={{ marginBottom: 16, marginRight: 10 }}>
                        門市匯入
                    </Button>
                </Col>
            </Row>
            <ResponsiveTable
                columns={columns}
                dataSource={this.state.stores}
                rowKey="id"
                pagination={{
                    total: this.state.total,
                    pageSize: this.state.pageSize,
                    onChange: (page, pageSize) => this.refresh(page, pageSize),
                }}
            />
            <Modal
                title="批次匯入"
                okText="上傳"
                cancelText="取消"
                visible={this.state.showBatchAddModal}
                onOk={this.handleBatchAddFileUpload}
                onCancel={this.hideBatchAddModal}
            >
                <Spin spinning={this.state.uploading}>
                    <div style={{ textAlign: 'right', marginBottom: 10 }}>
                        <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_URL + '/api/stores/import/sample'}>
                            <Button>下載範本</Button>
                        </a>
                    </div>
                    <Upload.Dragger
                        name="file"
                        multiple={false}
                        beforeUpload={this.handleBatchAddFileBeforeUpload}
                        onRemove={this.handleBatchAddFileOnRemove}
                        onChange={this.handleBatchAddFileChange}
                        onDrop={this.handleBatchAddFileDrop}
                        showUploadList={true}
                        fileList={this.state.fileList}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">點選或拖曳檔案到此區域來上傳檔案</p>
                        <p className="ant-upload-hint"></p>
                    </Upload.Dragger>
                </Spin>
            </Modal>
            <DeleteModal ref={this.deleteModal} title="門市" onDelete={this.handleDelete} />

        </div>;
    }
}

export default AdminStorePage;
