import './App.less';
import * as echarts from 'echarts';
import { Skeleton } from 'antd';
import LoginPage from './containers/LoginPage';
import {
  HashRouter,
  Routes,
  Route,
} from 'react-router-dom';
import MainLayout from './containers/MainLayout';

import CommDashboardPage from './containers/common/CommDashboardPage';

import AdminActivityPage from './containers/admin/AdminActivityPage';
import AdminCouponPage from './containers/admin/AdminCouponPage';
import AdminCustomerPage from './containers/admin/AdminCustomerPage';
import AdminUserPage from './containers/admin/AdminUserPage';
import AdminStorePage from './containers/admin/AdminStorePage';
import AdminSettingPage from './containers/admin/AdminSettingPage';
import AdminActivityReportPage from './containers/admin/reports/AdminActivityReportPage';
import AdminCustomerReport from './containers/admin/reports/AdminCustomerReportPage';
import AdminStoreReport from './containers/admin/reports/AdminStoreReportPage';

import StoreCouponPage from './containers/store/StoreCouponPage';
import StoreCustomerPage from './containers/store/StoreCustomerPage';
// import StoreUserPage from './containers/store/StoreUserPage';
import StoreScanPage from './containers/store/StoreScanPage';
import AdminActivityEditPage from './containers/admin/AdminActivityEditPage';
import StoreProfilePage from './containers/store/StoreProfilePage';
import AdminStoreEditPage from './containers/admin/AdminStoreEditPage';
import AdminUserEditPage from './containers/admin/AdminUserEditPage';
import StoreUserEditPage from './containers/store/StoreUserEditPage';
import React from 'react';
import GoodHoDayAPI from './api/GoodHoDayAPI';
import PermissionManager from './PermissionManager';
import NotFoundPage from './containers/NotFoundPage';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      roles: [],
    }
    PermissionManager.init(this);
    const echartTheme = require('./echart/omohoplus.project.json');
    echarts.registerTheme('omohoplus', echartTheme.theme);
  }
  updateRoles = (roles) => {
    this.setState({ roles: roles });
  }
  componentDidMount() {
    GoodHoDayAPI.auth.loginWithToken().then((response) => {
      if (response) {
        console.log("Login success");
      } else {
        console.log("Login failed");
      }
    }).catch((error) => {
      console.log("Login error: ", error);
    }).finally(() => {
      this.setState({ isReady: true });
    });
  }
  render() {
    if (!this.state.isReady) {
      return <Skeleton active={true} />;
    }
    const roles = PermissionManager.getRoles();
    return (
      <HashRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/admin" element={<MainLayout><CommDashboardPage /></MainLayout>} />,
          {/* Admin Pages */}
          {roles.indexOf(PermissionManager.ROLE_ADMIN) >= 0 && [
            <Route key="admin-activities" path="/admin/activities" element={<MainLayout><AdminActivityPage /></MainLayout>} />,
            <Route key="admin-activities-create" path="/admin/activities/create" element={<MainLayout><AdminActivityEditPage /></MainLayout>} />,
            <Route key="admin-activities-edit" path="/admin/activities/:activityId" element={<MainLayout><AdminActivityEditPage /></MainLayout>} />,
            <Route key="admin-coupons" path="/admin/coupons" element={<MainLayout><AdminCouponPage /></MainLayout>} />,
            <Route key="admin-customers" path="/admin/customers" element={<MainLayout><AdminCustomerPage /></MainLayout>} />,
            <Route key="admin-users" path="/admin/users" element={<MainLayout><AdminUserPage /></MainLayout>} />,
            <Route key="admin-users-create" path="/admin/users/create" element={<MainLayout><AdminUserEditPage /></MainLayout>} />,
            <Route key="admin-users-edit" path="/admin/users/:userId" element={<MainLayout><AdminUserEditPage /></MainLayout>} />,
            <Route key="admin-store" path="/admin/stores" element={<MainLayout><AdminStorePage /></MainLayout>} />,
            <Route key="admin-store-create" path="/admin/stores/create" element={<MainLayout><AdminStoreEditPage /></MainLayout>} />,
            <Route key="admin-store-edit" path="/admin/stores/:storeId" element={<MainLayout><AdminStoreEditPage /></MainLayout>} />,
            <Route key="admin-report-activity" path="/admin/reports/activity" element={<MainLayout><AdminActivityReportPage /></MainLayout>} />,
            <Route key="admin-report-customer" path="/admin/reports/customer" element={<MainLayout><AdminCustomerReport /></MainLayout>} />,
            <Route key="admin-report-store" path="/admin/reports/store" element={<MainLayout><AdminStoreReport /></MainLayout>} />,
            <Route key="admin-report" path="/admin/reports" element={<MainLayout></MainLayout>} />,
            <Route key="admin-settings" path="/admin/settings" element={<MainLayout><AdminSettingPage /></MainLayout>} />,
          ]}
          {/* Brand Pages */}
          {roles.indexOf(PermissionManager.ROLE_BRAND) >= 0 && []}
          {/* Shop Pages */}
          {roles.indexOf(PermissionManager.ROLE_CLERK) >= 0 && [
            <Route key="store-profile" path="/admin/store/profile" element={<MainLayout><StoreProfilePage /></MainLayout>} />,
            <Route key="store-coupons" path="/admin/store/coupons" element={<MainLayout><StoreCouponPage /></MainLayout>} />,
            <Route key="store-customer" path="/admin/store/customers" element={<MainLayout><StoreCustomerPage /></MainLayout>} />,
            // <Route path="/admin/store/users" element={<MainLayout><StoreUserPage /></MainLayout>} />,
            <Route key="store-users-create" path="/admin/store/users/create" element={<MainLayout><StoreUserEditPage /></MainLayout>} />,
            <Route key="store-scan" path="/admin/store/scan" element={<MainLayout><StoreScanPage /></MainLayout>} />,
          ]}
          <Route key="brand-login" path="/:urlCode" element={<LoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </HashRouter>

    );
  }
}

export default App;
