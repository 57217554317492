import React, { Component } from 'react';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import ResponsiveTable from '../../components/ResponsiveTable';
import { SearchOutlined } from '@ant-design/icons';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import ActivitySelect from '../../components/ActivitySelect';
import moment from "moment";
import Breadcrumb from '../../components/Breadcrumb';

const columns = [
  {
    title: '領券時間',
    key: 'created_at',
    dataIndex: 'created_at',
    render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    sorter: (a, b) => a.created_at - b.created_at,
    width: 200,
  },
  {
    title: '姓名',
    dataIndex: 'customer.name',
    key: 'customer.name',
    render: (text, record) => record.customer.name,
    width: 150,
  },
  {
    title: '電話',
    dataIndex: 'customer.phone',
    key: 'customer.phone',
    render: (text, record) => <a href={`tel:${record.customer.phone}`}>{record.customer.phone}</a>,
    width: 100,
  },

  {
    title: 'E-mail',
    dataIndex: 'customer.email',
    key: 'customer.email',
    render: (text, record) => <a href={`mailto:${record.customer.email}`}>{record.customer.email}</a>,
    width: 100,
  },
  {
    title: '優惠碼',
    children: [
      {
        title: '使用次數',
        key: 'discounttimes',
        dataIndex: 'discounttimes',
        sorter: (a, b) => a.discounttimes - b.discounttimes,
        width: 150,
      },
      {
        title: '最後核銷',
        key: 'discountlastusedtime',
        dataIndex: 'discountlastusedtime',
        sorter: (a, b) => a.discountlastusedtime - b.discountlastusedtime,
        width: 150,
      },
      {
        title: '訂單編號',
        key: 'discountlastorderserialno',
        dataIndex: 'discountlastorderserialno',
        width: 150,
      }
    ]
  },
  {
    title: '來店碼',
    children: [
      {
        title: '使用次數',
        key: 'giveawaytimes',
        dataIndex: 'giveawaytimes',
        sorter: (a, b) => a.giveawaytimes - b.giveawaytimes,
        width: 150,
      },
      {
        title: '最後核銷',
        key: 'giveawaylastusedtime',
        dataIndex: 'giveawaylastusedtime',
        sorter: (a, b) => a.giveawaylastusedtime - b.giveawaylastusedtime,
        width: 150,
      },
      {
        title: '訂單編號',
        key: 'giveawaylastorderserialno',
        dataIndex: 'giveawaylastorderserialno',
        width: 150,
      }
    ]
  },

  // {
  //   title: '動作',
  //   key: 'action',
  //   render: (text, record) => (
  //     <Space size="middle">
  //       <a>修改</a>
  //       <a>刪除</a>
  //     </Space>
  //   ),
  // },
];

export class StoreCouponPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeId: null,
      loading: false,
      brandId: null,
      activityId: null,
      coupons: [],
      query: {},
      total: null,
      pageSize: 10,
    };
    this.form = React.createRef();

  }
  handleChangeStore = (storeId, record) => {
    //console.log(storeId, record);
    if (!record) return;
    this.form.current.setFieldsValue({ activity_id: null });
    this.setState({ storeId: storeId, brandId: record.brand_id }, this.form.current.submit());
  }
  refresh = (page, pageSize) => {
    if (!this.state.activityId) return;
    this.setState({ loading: true });
    GoodHoDayAPI.activities.coupons.index(this.state.activityId, this.state.query, page, pageSize).then((response) => {
      const result = response.data;
      this.setState({
        coupons: result.data,
        total: result.total,
        pageSize: result.per_page,
      });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }
  // handleBrandChange = (brandId) => {
  //   this.form.current.setFieldsValue({ activity_id: null });
  //   this.setState({ brandId: brandId }, this.form.current.submit());
  // }
  handleActivityChange = (activityId) => {
    this.setState({ activityId: activityId }, this.form.current.submit());
  }
  handleFinish = (values) => {
    //console.log(values);
    if (values.useddate)
      values.useddate = values.useddate.format("YYYY-MM-DD");
    if (values.createddate)
      values.createddate = values.createddate.format("YYYY-MM-DD");
    values.store_id = this.state.storeId;
    this.setState({ query: values }, () => this.refresh());
  }
  render() {
    return <div>
      <Breadcrumb />
      <h1>優惠券追蹤</h1>
      <Row style={{ marginBottom: 10 }} justify="end">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <AuthStoreSelect style={{ width: '100%' }} onChange={this.handleChangeStore} />
        </Col>
      </Row>
      <Form ref={this.form} onFinish={this.handleFinish}>
        <Row gutter={10}>
          {/* <Col xs={24} sm={6} md={6} lg={6} xl={6}>
            <Form.Item name="brand_id">
              <BrandSelect onChange={this.handleBrandChange} />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={10} md={10} lg={10} xl={10}>
            <Form.Item name="activity_id">
              <ActivitySelect brandid={this.state.brandId} onChange={this.handleActivityChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={24} sm={4} md={4} lg={4} xl={4}>
            <Form.Item name="createddate">
              <DatePicker style={{ width: '100%' }} placeholder="領券日期" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={4} md={4} lg={4} xl={4}>
            <Form.Item name="useddate">
              <DatePicker style={{ width: '100%' }} placeholder="核銷日期" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={4} md={4} lg={4} xl={4}>
            <Form.Item name="keyword">
              <Input placeholder="關鍵字" allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={4} md={4} lg={4} xl={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查詢</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <ResponsiveTable
        columns={columns}
        dataSource={this.state.coupons}
        rowKey="id"
        pagination={{
          total: this.state.total,
          pageSize: this.state.pageSize,
          onChange: (page, pageSize) => this.refresh(page, pageSize),
        }}
      />    </div>;
  }
}

export default StoreCouponPage;
