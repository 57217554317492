import { Form, Input, Checkbox, Button, Radio, Row, Col, Skeleton } from 'antd';
import React, { Component } from 'react';
import GoodHoDayAPI from '../api/GoodHoDayAPI';
import withRouterNavigateHook from '../hoc/withRouterNavigateHook';
import LoginBrandSelect from '../components/LoginBrandSelect';
import PublicPage from './PublicPage';
import withRouterParamsHook from '../hoc/withRouterParamsHook';

export class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loginType: undefined,
            brandId: null,
            brand: null,
        };
    }
    componentDidMount() {
        this.reload();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.params !== prevProps.params) {
            this.reload();
        }
    }
    reload = () => {
        const { navigate, params } = this.props;
        if (params.urlCode) {
            this.setState({ loading: true });
            GoodHoDayAPI.auth.brands({ urlcode: params.urlCode }, 1, 999).then((response) => {
                const result = response.data;
                const brands = result.data;
                const brand = brands.find((row) => row.urlcode === params.urlCode);
                if (brand) {
                    this.setState({ brandId: brand.id, brand: brand, loading: false });
                } else {
                    this.setState({ loading: false });
                    setTimeout(() => { navigate('/') }, 0);
                }
            });
        } else {
            this.setState({ brandId: null, brand: null });
        }
        if (GoodHoDayAPI.auth.isLogined()) {
            console.log("Redirect to admin panel");
            setTimeout(() => { navigate('/admin', { replace: true }) }, 0);
        }
    }
    handleFinal = (values) => {
        const { navigate } = this.props;
        if (this.state.brand) {
            values.type = 'brand';
            values.brand_id = this.state.brand.id;
        }
        GoodHoDayAPI.auth.login(values.email, values.password, values.remember, values.type, values.brand_id).then((response) => {
            if (response) {
                navigate("/admin");
            }
        });
    }
    handleTypeChange = (e) => {
        //console.log(e.target.value);
        this.setState({ loginType: e.target.value });
    }
    render() {
        const options = [
            { label: '品牌', value: 'brand' },
            { label: '使用者', value: undefined },
            // { label: '門市', value: 'store' },
        ];
        return <PublicPage>
            {this.state.loading ? <Skeleton /> :
                <>
                    <h1 style={{ textAlign: 'center' }}><img src="/login-logo.png" alt="OMO Ho+" style={{ height: 100, marginBottom: 5 }} /></h1>
                    <Form className="login-form" initialValues={{ type: this.state.loginType }} onFinish={this.handleFinal} layout="vertical">
                        {this.state.brand ? <>
                            <Form.Item label="品牌">
                                {this.state.brand ? this.state.brand.name : ''}
                            </Form.Item>
                        </> : <>
                            {false && <Form.Item label="登入類型" name="type">
                                <Radio.Group options={options} onChange={this.handleTypeChange} />
                            </Form.Item>}
                            <Form.Item label="品牌" name="brand_id" style={{ display: this.state.loginType === 'brand' ? '' : 'none' }}>
                                <LoginBrandSelect />
                            </Form.Item>
                        </>}
                        <Form.Item label="電子信箱" name="email">
                            <Input />
                        </Form.Item>
                        <Form.Item label="密碼" name="password">
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="remember" valuePropName="checked">
                                        <Checkbox>記住我</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-btn" style={{ width: 100 }}>
                                            登入
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </>}
        </PublicPage>;
    }
}

export default withRouterParamsHook(withRouterNavigateHook(LoginPage));
