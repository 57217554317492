import { Table } from 'antd'
import React, { Component } from 'react'

export class ResponsiveTable extends Component {
  render() {
    const { ...props } = this.props;
    return (
      <Table scroll={{ x: 'max-content' }} {...props} />
    )
  }
}

export default ResponsiveTable