import { Col, Row, Select, Spin, Tabs } from 'antd'
import React, { Component } from 'react'
import Card from '../../../../components/Card'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from '../../../../components/googlemaps/Map';
import getGPSCoordByName from '../../../../api/getGPSCoordByName';
import RegionDetail from './RegionDetail';
import RegionStoreDetail from './RegionStoreDetail';
import RegionStoreUsageDetail from './RegionStoreUsageDetail';
import Marker from '../../../../components/googlemaps/Marker';
import GoodHoDayAPI from '../../../../api/GoodHoDayAPI';

export class StoreRegionReportL2 extends Component {
    state = {
        loading: false,
        activeDetailPane: 'region',
        storeDataType: 'store',
        selectedStoreId: null,
        markers: [],
        stores: [],
    };
    componentDidMount() {
        this.refresh();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.query !== this.props.query) {
            this.refresh();
        }
        if (prevProps.region !== this.props.region) {
            this.reset();
            this.refresh();
        }
    }
    reset = () => {
        this.setState({
            activeDetailPane: 'region',
            selectedStoreId: null,
        });
    }
    refresh = async () => {
        const query = this.props.query;
        // console.log("l2chart refresh", query);

        this.setState({ loading: true });

        // update markers
        const stores = await this.fetchStores(query);
        //console.log(stores);

        let dataFieldName = null;
        switch (this.state.storeDataType) {
            default:
            case 'store':
                dataFieldName = 'issueCount';
                break;
            case 'store-usage':
                dataFieldName = 'consumeCount'
        }
        const markers = stores.filter((row) => !!row.gpslocation).map((row) => {
            return {
                ...row,
                value: row[dataFieldName].toString(),
                ...this.gpsCoordStrToOb(row.gpslocation),
                selected: false
            };
        })
        this.setState({
            loading: false,
            markers: markers
            // markers: [
            //     { storeId: 1, lat: 25.03586, lng: 121.53247, value: Math.round(Math.random() * 2000).toString(), selected: false },
            //     { storeId: 2, lat: 25.04686, lng: 121.54347, value: Math.round(Math.random() * 2000).toString(), selected: false }
            // ]
        });
    }

    fetchStores = (query) => {
        const regionId = this.props.region.id;
        //console.log("regionId", regionId);
        return GoodHoDayAPI.reports.store.region.store.index(regionId, query).then((response) => {
            const result = response.data;
            //console.log(result);
            return result;
        });
    }
    gpsCoordStrToOb = (str) => {
        const [lat, lng] = str.split(",");
        //console.log(str, lat, lng);
        return { lat: parseFloat(lat), lng: parseFloat(lng) };

    }
    handleChangeStoreDataType = async (value) => {
        this.setState({
            storeDataType: value,
        }, () => this.refresh());
    }
    handleSelectDetailPane = (key) => {
        this.setState({ activeDetailPane: key });
    }
    handleClickMarker = (storeId, e) => {
        //console.log("marker clicked", storeId, e);

        const lastSelectedMarker = this.state.markers.find((row) => row.id === this.state.selectedStoreId);
        if (lastSelectedMarker) {
            lastSelectedMarker.selected = false;
        }
        const selectedMarker = this.state.markers.find((row) => row.id === storeId);
        if (selectedMarker) {
            selectedMarker.selected = true;
        }
        this.setState({
            selectedStoreId: storeId,
            selectedStore: {},
            activeDetailPane: this.state.storeDataType,
            markers: this.state.markers
        });
    }
    render() {
        if (!this.props.region) {
            return <p>請先選擇地區</p>;
        }
        const render = (status) => {
            return <h1>{status}</h1>;
        };
        const center = getGPSCoordByName(this.props.region.name);
        return (
            <Spin spinning={this.state.loading}>
                <Row gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={14}>
                        <Card>
                            <Row gutter={[10, 10]}>
                                <Col span={12}>
                                    <Select onChange={this.handleChangeStoreDataType} value={this.state.storeDataType}>
                                        <Select.Option value={'store'}>門市優惠券</Select.Option>
                                        <Select.Option value={'store-usage'}>來店使用狀況</Select.Option>
                                    </Select>
                                </Col>
                                <Col span={12}>
                                    <div style={{ color: '#717171', float: 'right' }}>
                                        可點選區域以查看詳細數據
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} render={render}>
                                        <Map
                                            center={center}
                                            // onClick={onClick}
                                            // onIdle={onIdle}
                                            zoom={center.zoom}
                                            style={{ flexGrow: "1", height: 800 }}
                                            xs={400}
                                        >
                                            {/* {clicks.map((latLng, i) => (
                                            <Marker key={i} position={latLng} />
                                        ))} */}
                                            {/* <Marker key='select-region' position={center} label={this.props.region} selected={true} /> */}
                                            {this.state.markers.map((row, i) => <Marker
                                                key={row.id}
                                                name={row.name}
                                                position={{ lat: row.lat, lng: row.lng }}
                                                iconType={this.state.storeDataType === 'store' ? 1 : 2}
                                                label={row.value}
                                                selected={row.selected}
                                                onClick={this.handleClickMarker.bind(this, row.id)} />)}
                                        </Map>
                                    </Wrapper>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={10}>
                        <Tabs type="card" activeKey={this.state.activeDetailPane} onChange={this.handleSelectDetailPane}>
                            <Tabs.TabPane tab="地區" key="region" disabled={!this.props.region}>
                                <RegionDetail regionid={this.props.region.id} query={this.props.query} onSelectStore={this.handleClickMarker} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="門市優惠券" key="store" disabled={!this.state.selectedStoreId}>
                                {this.state.selectedStoreId ?
                                    <RegionStoreDetail regionid={this.props.region.id} storeid={this.state.selectedStoreId} query={this.props.query} /> :
                                    <p>請先選擇門市</p>}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="來店使用狀況" key="store-usage" disabled={!this.state.selectedStoreId}>
                                {this.state.selectedStoreId ?
                                    <RegionStoreUsageDetail regionid={this.props.region.id} storeid={this.state.selectedStoreId} query={this.props.query} /> :
                                    <p>請先選擇門市</p>}
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Spin>
        )
    }
}

export default StoreRegionReportL2