
import React, { Component } from 'react';
import { Col, Row, DatePicker, Spin, Form } from 'antd';
import BrandSelect from '../../../components/BrandSelect';
import ActivitySelect from '../../../components/ActivitySelect';
import GoodHoDayAPI from '../../../api/GoodHoDayAPI';
import moment from 'moment';
import Breadcrumb from '../../../components/Breadcrumb';
import Card from '../../../components/Card';
import ReactEChartsLoadingWrapper from '../../../components/chart/ReactEChartsLoadingWrapper';
import CustomerStageFunnelChart from '../../../components/chart/CustomerStageFunnelChart';
import SvgIconPath from '../../../api/SvgIconPath';
const { RangePicker } = DatePicker;

export class AdminActivityReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandId: null,
      activityId: null,
      startDate: moment().subtract(1, 'month').format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      loading: false,
      newCouponChartOption: null,
      usageByDiscountCouponChartOption: null,
      usageByGiveawayCouponChartOption: null,
    };
    this.funnelChart = React.createRef();
  }
  componentDidMount() {
    this.refresh();
  }
  refresh = async () => {
    this.setState({ loading: true });
    const query = {
      brand_id: this.state.brandId,
      activity_id: this.state.activityId,
      startdate: this.state.startDate,
      enddate: this.state.endDate,
    };
    //console.log("query", query);
    await this.updateNewCouponChart(query);
    await this.updateUsageByDiscountCouponChart(query);
    await this.updateUsageByGiveawayCouponChart(query);
    this.funnelChart.current.refresh(query);
    this.setState({ loading: false });
  }
  updateNewCouponChart = async (query) => {
    const response = await GoodHoDayAPI.reports.activity.total(query);
    const result = response.data;
    //console.log("updateNewCouponChart", result);
    const options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        top: 5,
        left: 0,
        icon: SvgIconPath.lineChartLegend,
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: result.map((row) => row.date),
        axisLabel: {
          formatter: (value, index) => {
            const [year, month, day] = value.split('-');
            return `${year}\n{bold|${month}}\n{bold|${day}}`;
          },
          rich: {
            bold: {
              fontWeight: 700
            }
          }
        }
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '發放優惠量',
          data: result.map((row) => row.coupontimes),
          type: 'line',
          smooth: true,
        }
      ]
    }
    this.setState({ newCouponChartOption: options });
    return options;
  }
  updateUsageByDiscountCouponChart = async (query) => {
    const response = await GoodHoDayAPI.reports.activity.discount(query);
    const result = response.data;
    //console.log("updateUsageByDiscountCouponChart", result);
    const options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        top: 5,
        left: 0,
        icon: SvgIconPath.lineChartLegend,
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: result.map((row) => row.date),
          axisLabel: {
            formatter: (value, index) => {
              const [year, month, day] = value.split('-');
              return `${year}\n{bold|${month}}\n{bold|${day}}`;
            },
            rich: {
              bold: {
                fontWeight: 700
              }
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '己建立數量',
          type: 'line',
          emphasis: {
            focus: 'series'
          },
          smooth: true,
          data: result.map((row) => row.total)
        },
        {
          name: '使用量',
          type: 'line',
          emphasis: {
            focus: 'series'
          },
          smooth: true,
          data: result.map((row) => row.usedtotal)
        },
        {
          name: '未使用量',
          type: 'line',
          emphasis: {
            focus: 'series'
          },
          smooth: true,
          data: result.map((row) => row.unusedtotal)
        },
      ]
    };
    this.setState({ usageByDiscountCouponChartOption: options });
    return options;
  }
  updateUsageByGiveawayCouponChart = async (query) => {
    const response = await GoodHoDayAPI.reports.activity.giveaway(query);
    const result = response.data;
    //console.log("updateUsageByGiveawayCouponChart", result);
    const options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        top: 5,
        left: 0,
        icon: SvgIconPath.lineChartLegend,
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            // icon: `image://${require('../../../echart/echartDownload.png')}`,
            // type: 'png',
            pixelRatio: 2,
            title: '下載'
          }
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: result.map((row) => row.date),
          axisLabel: {
            formatter: (value, index) => {
              const [year, month, day] = value.split('-');
              return `${year}\n{bold|${month}}\n{bold|${day}}`;
            },
            rich: {
              bold: {
                fontWeight: 700
              }
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '己建立數量',
          type: 'line',
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          data: result.map((row) => row.total)
        },
        {
          name: '使用量',
          type: 'line',
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          data: result.map((row) => row.usedtotal)
        },
        {
          name: '未使用量',
          type: 'line',
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          data: result.map((row) => row.unusedtotal)
        },
      ]
    };
    this.setState({ usageByGiveawayCouponChartOption: options });
    return options;
  }
  handleBrandChange = (value) => {
    this.setState({ brandId: value }, () => this.refresh());
  }
  handleActivityChange = (value) => {
    this.setState({ activityId: value }, () => this.refresh());
  }
  handlePeriodChange = (value) => {
    const [start, end] = value;
    this.setState({
      startDate: start.format("YYYY-MM-DD"),
      endDate: end.format("YYYY-MM-DD")
    }, () => this.refresh())
    console.log(value, start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
  }
  render() {
    return <div>
      <Breadcrumb />
      <h1>活動數據報表</h1>
      <Form layout="inline">
        <Form.Item>
          <RangePicker
            value={[moment(this.state.startDate), moment(this.state.endDate)]}
            onChange={this.handlePeriodChange}
            allowClear={false}
            placeholder={["開始日期", "結束日期"]} />
        </Form.Item>
        <Form.Item >
          <BrandSelect onChange={this.handleBrandChange} />
        </Form.Item>
        <Form.Item>
          <ActivitySelect brandid={this.state.brandId} onChange={this.handleActivityChange} />
        </Form.Item>
      </Form>
      <Spin spinning={this.state.loading}>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>每⽇發放優惠量</h3>
            <Card>
              <ReactEChartsLoadingWrapper option={this.state.newCouponChartOption} style={{ height: 300 }} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>優惠碼使⽤分析</h3>
            <Card>
              <ReactEChartsLoadingWrapper option={this.state.usageByDiscountCouponChartOption} style={{ height: 300 }} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>來店碼使⽤分析</h3>
            <Card>
              <ReactEChartsLoadingWrapper option={this.state.usageByGiveawayCouponChartOption} style={{ height: 300 }} />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h3>顧客⾏為流程</h3>
            <Card>
              <span style={{ color: '#717171' }}>活動開始日 至 今日 期間之資料</span>
              <CustomerStageFunnelChart ref={this.funnelChart} />
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>;
  }
}

export default AdminActivityReportPage;
