import { Layout } from 'antd';
import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import PropTypes from 'prop-types'
import './QrCodeScanScreen.css'

export class QrCodeScanScreen extends Component {
    static propTypes = {
        onScan: PropTypes.func,
        onError: PropTypes.func,
        onClose: PropTypes.func
    }
    render() {
        return (
            this.props.visible && <div style={{ display: 'block', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 99 }}>
                <Layout style={{ height: '100vh' }}>
                    <Layout.Content style={{ overflow: 'hidden' }}>
                        <a onClick={this.props.onClose} className="close" />
                        <div style={{ marginTop: '20%', textAlign: 'center', fontSize: '1.5rem' }}>請掃描優惠券 QRCode</div>
                        <div className="scannerBox">
                            <QrReader
                                delay={300}
                                onError={this.props.onError}
                                onScan={this.props.onScan}
                            />
                        </div>
                    </Layout.Content>
                </Layout>
            </div>
        )
    }
}

export default QrCodeScanScreen
