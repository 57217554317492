import { Select } from 'antd'
import React, { Component } from 'react'
import GoodHoDayAPI from '../api/GoodHoDayAPI';

export class StoreSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            stores: [],
        };
    }
    componentDidMount() {
        this.refresh();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.brandid !== prevProps.brandid) {
            this.refresh();
        }
    }
    refresh = () => {
        if (!this.props.brandid) {
            this.setState({ stores: [] });
            return;
        }
        this.setState({ loading: true });
        GoodHoDayAPI.stores.index({ brand_id: this.props.brandid }, 1, 999).then((response) => {
            const result = response.data;
            this.setState({
                stores: result.data
            });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    render() {
        return (
            <Select
                placeholder="門市"
                showSearch
                optionFilterProp="name"
                filterOption={(input, option) =>
                    option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ minWidth: 150, marginRight: 10 }}
                {...this.props}>
                {this.state.stores.map((store) => <Select.Option key={store.id} value={store.id}>{store.name} ({store.id})</Select.Option>)}
            </Select>
        )
    }
}

export default StoreSelect