import { Col, Row, Table, Typography } from 'antd'
import React, { Component } from 'react'
import AnimatedNumber from "animated-number-react";
import Card from '../../../../components/Card'
import CustomerRegionChart from '../../../../components/chart/CustomerRegionChart'
import DashboardCard from '../../../../components/DashboardCard'
import ResponsiveTable from '../../../../components/ResponsiveTable';

export class StoreRegionReportL1 extends Component {
    regionChart = React.createRef();
    state = {
        result: {
            details: [],
        },
    };
    componentDidMount() {
        this.refresh();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.query !== this.props.query) {
            this.refresh();
        }
    }
    handleRegionChange = (name, detail) => {
        if (this.props.onSelectRegion) {
            this.props.onSelectRegion(name, detail);
        }
    }
    refresh = async () => {
        const query = this.props.query;
        // console.log("l1chart refresh", query);
        this.regionChart.current.refresh(query);
    }
    handleFetchRegions = (result) => {
        this.setState({ result: result });
    }
    getDetail = (index) => {
        if (!this.state.result) {
            return {};
        }
        if (index >= this.state.result.details.length) {
            return {};
        }
        return this.state.result.details[index];
    }
    render() {
        const columns = [
            {
                title: '城市',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => <Typography.Link onClick={this.handleRegionChange.bind(this, record.name, record)}>{text}</Typography.Link>,
            },
            {
                title: '門市數',
                dataIndex: 'storeCount',
                key: 'storeCount',
            },
            {
                title: '發放總數',
                dataIndex: 'issueCount',
                key: 'issueCount',
            },
            // {
            //     title: '發放總數佔比',
            //     dataIndex: 'totalIssueRate',
            //     key: 'totalIssueRate',
            //     render: (value) => (value * 100).toFixed(2) + ' %'
            // },
            {
                title: '核銷總數',
                dataIndex: 'consumeCount',
                key: 'consumeCount',
            },
            {
                title: '核銷總數佔比',
                dataIndex: 'totalConsumeRate',
                key: 'totalConsumeRate',
                render: (value) => (value * 100).toFixed(2) + ' %'
            },
        ];
        return (
            <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={14}>

                    <Card>
                        <div style={{ color: '#717171', textAlign: 'right' }}>可點選區域以查看詳細數據</div>
                        <CustomerRegionChart ref={this.regionChart} lazyLoad={true} onChange={this.handleRegionChange} onFetchRegions={this.handleFetchRegions} />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={10}>
                    <Row gutter={[10, 10]}>
                        <Col span={24} sm={12}>
                            <Card style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <span style={{ color: '#A4A3A3', fontWeight: 700, fontSize: '1.1rem', lineHeight: '2.5rem' }}>活動發放總數</span>
                                </div>
                                <div style={{ flex: 1, textAlign: 'right' }}>
                                    <span style={{ color: '#717171', fontWeight: 700, fontSize: '2.5rem', lineHeight: '2.5rem' }}>
                                        <AnimatedNumber value={this.state.result.issueCount} formatValue={(value) => value.toFixed(0)} />
                                    </span>
                                </div>
                            </Card>
                        </Col>
                        <Col span={24} sm={12}>
                            <Card style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <span style={{ color: '#A4A3A3', fontWeight: 700, fontSize: '1.1rem', lineHeight: '2.5rem' }}>活動核銷總數</span>
                                </div>
                                <div style={{ flex: 1, textAlign: 'right' }}>
                                    <span style={{ color: '#717171', fontWeight: 700, fontSize: '2.5rem', lineHeight: '2.5rem' }}>
                                        <AnimatedNumber value={this.state.result.consumeCount} formatValue={(value) => value.toFixed(0)} />
                                    </span>
                                </div>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <DashboardCard
                                img={require('../../../../images/icons/gold.png')}
                                color="rgba(255, 217, 84, 1)"
                                title={this.getDetail(0).name}
                                rate={this.getDetail(0).totalConsumeRate * 100}
                                count={this.getDetail(0).consumeCount} />
                        </Col>
                        <Col span={8}>
                            <DashboardCard
                                img={require('../../../../images/icons/silver.png')}
                                color="rgba(212, 212, 212, 1)"
                                title={this.getDetail(1).name}
                                rate={this.getDetail(1).totalConsumeRate * 100}
                                count={this.getDetail(1).consumeCount} />
                        </Col>
                        <Col span={8}>
                            <DashboardCard
                                img={require('../../../../images/icons/bronze.png')}
                                color="rgba(199, 142, 89, 1)"
                                title={this.getDetail(2).name}
                                rate={this.getDetail(2).totalConsumeRate * 100}
                                count={this.getDetail(2).consumeCount} />
                        </Col>
                        <Col span={24}>
                            <Card>
                                <h3>活動區域核銷排行</h3>
                                <ResponsiveTable columns={columns} dataSource={this.state.result.details} rowKey="id" />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default StoreRegionReportL1