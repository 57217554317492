import { Button, Form, Input, notification, Select } from 'antd'
import React, { Component } from 'react'
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import Breadcrumb from '../../components/Breadcrumb';
import withRouterParamsHook from '../../hoc/withRouterParamsHook';

const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};
export class AdminUserEditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            mode: 'store',
            userId: null
        };
        this.form = React.createRef();
    }
    componentDidMount() {
        const userId = this.props.params.userId;
        this.fetchData(userId);
    }
    fetchData = (userId) => {
        if (!userId) return;
        GoodHoDayAPI.users.show(userId).then((response) => {
            const userData = response.data;
            this.setState({
                mode: 'edit',
                userId: userId,
                loading: false,
            });
            this.form.current.setFieldsValue(userData);
        });
    }
    handleFinish = (values) => {
        if (this.state.mode === 'store') {
            this.setState({ loading: true });

            GoodHoDayAPI.users.store(values).then((response) => {
                notification.info({ message: '新增成功' });
            }).finally(() => {
                this.setState({ loading: false })
            })
        } else {
            this.setState({ loading: true });

            GoodHoDayAPI.users.update(this.state.userId, values).then((response) => {
                notification.info({ message: '修改成功' });

            }).finally(() => {
                this.setState({ loading: false })
            })
        }
    }
    handleReset = () => {
        this.form.current.resetFields();
    };
    render() {
        return (
            <div>
                <Breadcrumb />
                <h1>{this.state.mode === 'store' ? '新增' : '修改'}帳號</h1>
                <Form ref={this.form} onFinish={this.handleFinish} onReset={this.handleReset} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                    <Form.Item label="帳號" name="email">
                        <Input placeholder="user@domain.com" />
                    </Form.Item>
                    <Form.Item label="密碼" name="password">
                        <Input.Password />
                    </Form.Item>
                    <Form.Item label="名稱" name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label="電話" name="phone">
                        <Input />
                    </Form.Item>
                    <Form.Item label="備註" name="comment">
                        <Input.TextArea cols={3} />
                    </Form.Item>
                    <Form.Item label="身份" name="isadmin">
                        <Select placeholder="管理者" style={{ minWidth: 100, marginRight: 10 }}>
                            <Select.Option value={1}>系統管理者</Select.Option>
                            <Select.Option value={0}>一般帳號</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} style={{ marginRight: 10 }}>
                            送出
                        </Button>
                        <Button htmlType="button">
                            重設
                        </Button>
                    </Form.Item>
                </Form>
                {/* <h3>權限</h3>
                <Row gutter={10} style={{ marginBottom: 20 }}>
                    <Col span={4} style={{ textAlign: 'right' }}>項目: </Col>
                    <Col span={4}>
                        <BrandSelect />
                    </Col>
                    <Col span={4}>
                        <Select placeholder="門市" value="all" style={{ minWidth: 150, marginRight: 10 }}>
                            <Select.Option value="all">全部</Select.Option>
                            <Select.Option>台北</Select.Option>
                        </Select>
                    </Col>
                    <Col span={4}>
                        <Select placeholder="身份" style={{ minWidth: 100, marginRight: 10 }}>
                            <Select.Option value="brand_admin">品牌管理者</Select.Option>
                            <Select.Option value="store-region-manager">門市區經理</Select.Option>
                            <Select.Option value="store-manager">門市店長</Select.Option>
                            <Select.Option value="store-employee">門市員工</Select.Option>
                        </Select>

                    </Col>
                    <Col span={4}>
                        <Button icon={<PlusOutlined />}>新增</Button>
                    </Col>
                </Row> */}
            </div>
        )
    }
}

export default withRouterParamsHook(AdminUserEditPage)