import { Select } from 'antd';
import React, { Component } from 'react';
import GoodHoDayAPI from '../api/GoodHoDayAPI';

export class ActivitySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            loading: false,
            value: props.value
        };
    }
    componentDidMount() {
        this.refresh();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.brandid !== this.props.brandid) {
            this.setState({ value: null }); // if brandid changed then clear value
            this.refresh();
        }
    }
    handleOnChange = (activityId) => {
        this.setState({ value: activityId });
        if (this.props.onChange) {
            this.props.onChange(activityId);
        }
    }
    refresh = () => {
        if (!this.props.brandid) {
            this.setState({ options: [] });
            return;
        }
        this.setState({ loading: true });
        GoodHoDayAPI.activities.index({ brand_id: this.props.brandid }, 1, 999).then((response) => {
            const activities = response.data.data;
            const defaultActivityId = activities.length > 0 ? activities[0].id:null;
            //console.log(activities);
            this.setState({ options: activities }, () => this.handleOnChange(this.state.value ?? defaultActivityId));
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    render() {
        return <Select
            placeholder="活動"
            loading={this.state.loading}
            optionFilterProp="name"
            filterOption={(input, option) =>
                option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            allowClear
            style={{ minWidth: 200, marginRight: 10 }}
            {...this.props}
            value={this.state.value}
            onChange={this.handleOnChange}>
            {this.state.options.map((option) => <Select.Option key={option.id} value={option.id}>{option.name} ({option.id})</Select.Option>)}
        </Select>;
    }
}

export default ActivitySelect;
