import { Form, Input, Modal, notification, Select, Spin } from 'antd'
import React, { Component } from 'react'
import GoodHoDayAPI from '../api/GoodHoDayAPI';

export class StoreClerkEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'store',
            clerkId: null,
            loading: false
        }
        this.form = React.createRef();
    }
    componentDidMount() {
        const storeId = this.props.storeid;
        const clerkId = this.props.clerkid;
        this.fetchData(storeId, clerkId);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.clerkid !== this.props.clerkid) {
            const storeId = this.props.storeid;
            const clerkId = this.props.clerkid;
            this.fetchData(storeId, clerkId);
        }
    }
    fetchData = (storeId, clerkId) => {
        if (!storeId || !clerkId) return;
        this.setState({ loading: true });
        GoodHoDayAPI.stores.clerks.show(storeId, clerkId).then((response) => {
            const result = response.data;
            this.setState({
                mode: 'edit',
                storeId: storeId,
                clerkId: clerkId
            });
            this.form.current.setFieldsValue(result);
        }).finally(() => {
            this.setState({ loading: false });
        })
    }
    handleFinish = async (values) => {
        console.log("handleFinish", values);
        if (this.state.mode === 'store') {
            this.setState({ loading: true });
            await GoodHoDayAPI.stores.clerks.store(this.props.storeid, values).then((response) => {
                notification.info({ message: "新增成功" });
            }).finally(() => {
                this.setState({ loading: false });
            })
        } else {
            this.setState({ loading: true });
            await GoodHoDayAPI.stores.clerks.update(this.props.storeid, this.state.clerkId, values).then((response) => {
                notification.info({ message: "修改成功" });
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
        if (this.props.onOk) {
            this.props.onOk();
        }
    }
    handleOk = () => {
        this.form.current.submit();
    }
    render() {
        return (
            <Modal
                {...this.props}
                onOk={this.handleOk}
                okText={this.state.mode === 'store' ? '新增' : '修改'}
                cancelText="取消">
                <Spin spinning={this.state.loading}>
                    <h1>{this.state.mode === 'store' ? '新增' : '修改'}門市聯絡人</h1>
                    <Form ref={this.form} onFinish={this.handleFinish} layout="vertical">
                        <Form.Item label="職稱" name="title">
                            <Select>
                                <Select.Option value="mainmanager">店長</Select.Option>
                                <Select.Option value="manager">店經理</Select.Option>
                                <Select.Option value="clerk">店員</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="姓名" name="name">
                            <Input />
                        </Form.Item>
                        <Form.Item label="電話" name="phone">
                            <Input />
                        </Form.Item>
                        <Form.Item label="E-mail" name="email">
                            <Input />
                        </Form.Item>
                        <Form.Item label="密碼" name="password" >
                            <Input.Password />
                        </Form.Item>
                        {/* <Form.Item label="權限" name="roles">
                            <Select>
                                <Select.Option>管理者</Select.Option>
                                <Select.Option>一般成員</Select.Option>
                            </Select>
                        </Form.Item> */}
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

export default StoreClerkEditModal
