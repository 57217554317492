import { Select } from 'antd';
import React, { Component } from 'react';
import GoodHoDayAPI from '../api/GoodHoDayAPI';

export class BrandSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            loading: false,
            value: props.value,
        };
    }
    componentDidMount() {
        this.refresh();
    }
    handleOnChange = (brandId) => {
        this.setState({ value: brandId });
        if (this.props.onChange) {
            this.props.onChange(brandId);
        }
    }
    refresh = () => {
        this.setState({ loading: true });
        GoodHoDayAPI.brands.index({}, 1, 999).then((response) => {
            const brands = response.data.data;
            const defaultBrandId = brands[0].id;
            this.setState({
                options: brands,
            }, () => this.handleOnChange(this.state.value ?? defaultBrandId));
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    render() {
        return <Select
            placeholder="品牌"
            loading={this.state.loading}
            style={{ minWidth: 150, marginRight: 10 }}
            {...this.props}
            value={this.state.value}
            onChange={this.handleOnChange}
        >
            {this.state.options.map((option) => <Select.Option key={option.id} value={option.id}>{option.name} ({option.id})</Select.Option>)}
        </Select>;
    }
}

export default BrandSelect;
