import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    HomeOutlined,
    BankOutlined,
    ShopOutlined,
    CalendarOutlined,
    CopyOutlined,
    ContactsOutlined,
    AreaChartOutlined,
    SettingOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import "./MainLayout.css";
import React, { Component } from "react";
import { Button, Col, Layout, Menu, Row } from "antd";
import GoodHoDayAPI from "../api/GoodHoDayAPI";
import withRouterNavigateHook from "../hoc/withRouterNavigateHook";
import PermissionManager from "../PermissionManager";
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

export class MainLayout extends Component {
    state = {
        collapsed: false,
    };

    componentDidMount() {
        const { navigate } = this.props;
        if (!GoodHoDayAPI.auth.isLogined()) {
            setTimeout(() => { navigate('/', { replace: true }) }, 0);
        }
    }
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    handleBreakpoint = (value) => {
        this.setState({
            breakpoint: value,
        });
    };
    hideMask = () => {
        this.toggle();
    };
    handleLogout = () => {
        const { navigate } = this.props;
        GoodHoDayAPI.auth.logout().then((response) => {
            if (response) {
                navigate("/");
            }
        })
    }
    refreshToken = () => {
        GoodHoDayAPI.auth.refresh();
    }
    render() {
        const roles = PermissionManager.getRoles();
        return (
            <Layout style={this.state.breakpoint ? { height: "100%" } : { minHeight: "100vh" }}>
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={this.state.collapsed}
                    breakpoint="md"
                    onBreakpoint={this.handleBreakpoint}
                    collapsedWidth={this.state.breakpoint ? 0 : 60}
                    style={
                        this.state.breakpoint
                            ? { position: "absolute", height: "100vh", zIndex: 99, overflowY: 'auto' }
                            : {}
                    }
                >
                    <div className="logo">
                        <img src="menu-logo.png" style={{ height: '100%', display: this.state.collapsed ? 'none' : 'unset' }} alt="Logo" />
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={["0"]}>
                        <Menu.Item key="0" icon={<HomeOutlined />}>
                            <Link to="/admin">首頁</Link>
                        </Menu.Item>
                        {roles.indexOf(PermissionManager.ROLE_ADMIN) >= 0 && [
                            <Menu.ItemGroup key="g-admin" title="管理">
                                {/* <Menu.Item key="admin-brands" icon={<BankOutlined />} disabled={true}>
                                    品牌管理
                                </Menu.Item> */}
                                <Menu.Item key="admin-stores" icon={<ShopOutlined />}>
                                    <Link to="/admin/stores">門市管理</Link>
                                </Menu.Item>
                                <Menu.Item key="admin-activities" icon={<CalendarOutlined />}>
                                    <Link to="/admin/activities">活動上架</Link>
                                </Menu.Item>
                                <Menu.Item key="admin-coupons" icon={<CopyOutlined />}>
                                    <Link to="/admin/coupons">優惠券管理</Link>
                                </Menu.Item>
                                <Menu.Item key="admin-customers" icon={<ContactsOutlined />}>
                                    <Link to="/admin/customers">顧客名單管理</Link>
                                </Menu.Item>
                                <SubMenu key="5" icon={<AreaChartOutlined />} title="績效管理">
                                    <Menu.Item key="admin-reports-activity">
                                        <Link to="/admin/reports/activity">活動數據報表</Link>
                                    </Menu.Item>
                                    <Menu.Item key="admin-reports-customer">
                                        <Link to="/admin/reports/customer">顧客行為報表</Link>
                                    </Menu.Item>
                                    <Menu.Item key="admin-reports-store">
                                        <Link to="/admin/reports/store">門市報表</Link>
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item key="4-1" icon={<UserOutlined />}>
                                    <Link to="/admin/users">帳號管理</Link>
                                </Menu.Item>
                                <Menu.Item key="6" icon={<SettingOutlined />}>
                                    <Link to="/admin/settings">設定</Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                        ]}
                        {roles.indexOf(PermissionManager.ROLE_BRAND) >= 0 && [
                            <Menu.ItemGroup key="g-brand" title="品牌">
                                <Menu.Item key="brand-profile" icon={<BankOutlined />} disabled={true}>
                                    品牌資料
                                </Menu.Item>
                                <Menu.Item key="brand-stores" icon={<ShopOutlined />} disabled={true}>
                                    門市管理
                                </Menu.Item>
                                <Menu.Item key="brand-coupons" icon={<CopyOutlined />} disabled={true}>
                                    品牌優惠券追蹤
                                </Menu.Item>
                                <Menu.Item key="brand-customers" icon={<ContactsOutlined />} disabled={true}>
                                    品牌顧客名單
                                </Menu.Item>
                                <Menu.Item key="brand-accounts" icon={<UserOutlined />} disabled={true}>
                                    品牌帳號管理
                                </Menu.Item>
                            </Menu.ItemGroup>
                        ]}
                        {roles.indexOf(PermissionManager.ROLE_CLERK) >= 0 && [
                            <Menu.ItemGroup key="g-shop" title="門市">
                                <Menu.Item key="shop-profile" icon={<ShopOutlined />}>
                                    <Link to="/admin/store/profile">門市資料</Link>
                                </Menu.Item>
                                <Menu.Item key="shop-coupons" icon={<CopyOutlined />}>
                                    <Link to="/admin/store/coupons">門市優惠券追蹤</Link>
                                </Menu.Item>
                                <Menu.Item key="shop-customers" icon={<ContactsOutlined />}>
                                    <Link to="/admin/store/customers">門市顧客名單</Link>
                                </Menu.Item>
                                {/* <Menu.Item key="shop-users" icon={<UserOutlined />}>
                                <Link to="/admin/store/users">門市帳號管理</Link>
                            </Menu.Item> */}
                                <Menu.Item key="shop-scan" icon={<SearchOutlined />}>
                                    <Link to="/admin/store/scan">掃描頁面</Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                        ]}
                    </Menu>
                </Sider>
                <div
                    onClick={this.hideMask}
                    style={{
                        display:
                            this.state.breakpoint && !this.state.collapsed ? "block" : "none",
                        position: "absolute",
                        height: "100vh",
                        width: "100%",
                        opacity: 1,
                        backgroundColor: "rgba(0,0,0,.45)",
                        animation: "antdDrawerFadeIn .3s cubic-bezier(.7,.3,.1,1)",
                        zIndex: 98,
                    }}
                ></div>

                <Layout className="site-layout" style={{}}>
                    <Header className="site-layout-header" style={{ padding: 0 }}>
                        <Row>
                            <Col flex="auto" style={{ overflow: 'hidden' }}>
                                {React.createElement(
                                    this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                                    {
                                        className: "trigger",
                                        onClick: this.toggle,
                                    }
                                )}
                                {/* {process.env.REACT_APP_URL} */}
                            </Col>
                            <Col style={{ padding: '0px 5px' }}>
                                <Link to="/admin/store/scan?scanner=1">
                                    <Button type="primary">掃描</Button>
                                </Link>
                                {/* <Button onClick={this.refreshToken}>Refresh Token</Button> */}
                            </Col>
                            <Col style={{ padding: '0px 5px', paddingRight: 15 }}>
                                <Button type="primary" onClick={this.handleLogout}>登出</Button>
                            </Col>
                        </Row>
                    </Header>
                    <Content style={{ overflowY: 'auto', position:'relative' }}>
                        <img src="/background.png" className="bg-image" alt="Background Logo" />
                        <div className="site-layout-background" style={{ margin: '24px 16px', padding: 5, minHeight: 360, position: 'relative' }}>
                            {this.props.children ? this.props.children : "無內容"}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default withRouterNavigateHook(MainLayout);
