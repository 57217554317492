import React from 'react'
import ReactECharts from 'echarts-for-react';
import { Skeleton, Grid } from 'antd';

export default function ReactEChartsLoadingWrapper({ style, ...props }) {
    const screens = Grid.useBreakpoint();
    let newStyle = { ...style };
    // xs, sm, md, lg, xl, xxl
    Object.keys(screens).forEach((screenSizeName) => {
        if (props[screenSizeName] && screens[screenSizeName] ) {
            newStyle.height = props[screenSizeName];
        }
    })
    return (
        <>{props.option ?
            <ReactECharts theme="omohoplus" style={newStyle} {...props} /> :
            <Skeleton style={newStyle} />}</>
    )
}
