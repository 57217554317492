import { Col, Row, Spin, Table, Typography } from 'antd'
import React, { Component } from 'react'
import AnimatedNumber from "animated-number-react";
import Card from '../../../../components/Card'
import DashboardCard from '../../../../components/DashboardCard'
import GoodHoDayAPI from '../../../../api/GoodHoDayAPI';
import ResponsiveTable from '../../../../components/ResponsiveTable';

export class RegionDetail extends Component {
    state = {
        loading: false,
        result: {},
    };
    componentDidMount() {
        this.refresh();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.regionid !== this.props.regionid) {
            this.refresh();
        }
        if (prevProps.query !== this.props.query) {
            this.refresh();
        }
    }
    refresh = async () => {
        const regionId = this.props.regionid;
        if (!regionId) return;
        // console.log("Fetching region detail: ", regionId);
        this.setState({ loading: true });
        await GoodHoDayAPI.reports.store.region.show(regionId, this.props.query).then((response) => {
            const result = response.data;
            this.setState({ result: result });
        });
        await this.fetchDetail(regionId);
        this.setState({ loading: false });
    }
    fetchDetail = (regionId) => {
        return GoodHoDayAPI.reports.store.region.store.index(regionId, this.props.query).then((response) => {
            // console.log("detail", response.data);
            this.setState({ detail: response.data });
        });
    }
    getDetail = (index) => {
        if (!this.state.detail) {
            return {};
        }
        if (index >= this.state.detail.length) {
            return {};
        }
        return this.state.detail[index];
    }
    handleSelectStore = (storeId) => {
        if (this.props.onSelectStore) {
            this.props.onSelectStore(storeId);
        }
    }
    render() {
        const columns = [
            {
                title: '分店',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => <Typography.Link onClick={this.handleSelectStore.bind(this, record.id)}>{text} {record.gpslocation ? '':'(無 GPS)'}</Typography.Link>,
            },
            {
                title: '發放總數',
                dataIndex: 'issueCount',
                key: 'issueCount',
            },
            // {
            //     title: '區域佔比',
            //     dataIndex: 'totalIssueRate',
            //     key: 'totalIssueRate',
            //     render: (value) => (value*100).toFixed(2) + ' %'
            // },
            {
                title: '核銷總數',
                dataIndex: 'consumeCount',
                key: 'consumeCount',
            },
            {
                title: '區域佔比',
                dataIndex: 'totalConsumeRate',
                key: 'totalConsumeRate',
                render: (value) => (value*100).toFixed(2) + ' %'
            },
        ];
        const result = this.state.result;
        return (
            <Spin spinning={this.state.loading}>
                <Row gutter={[10, 10]}>
                    <Col span={24} xxl={12}>
                        <Card style={{ display: 'flex' }}>
                            <div style={{ flex: 2 }}>
                                <span style={{ color: '#A4A3A3', fontWeight: 700, fontSize: '1.1rem', lineHeight: '2.5rem' }}>{result.name} 核銷總數</span>
                            </div>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                <span style={{ color: '#717171', fontWeight: 700, fontSize: '2.5rem', lineHeight: '2.5rem' }}>
                                    <AnimatedNumber value={result.consumeCount} formatValue={(value) => value.toFixed(0)} />
                                </span>
                            </div>
                        </Card>
                    </Col>
                    <Col span={24} xxl={12}>
                        <Card style={{ display: 'flex' }}>
                            <div style={{ flex: 2 }}>
                                <img src={require('../../../../images/icons/gold-small.png')} alt="Gold" style={{ paddingBottom: 5, marginRight: 5, verticalAlign: 'middle' }} />
                                <span style={{ color: '#A4A3A3', fontWeight: 700, fontSize: '1.1rem', lineHeight: '2.5rem' }}>總核銷數佔比</span>
                            </div>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                <span style={{ color: '#717171', fontWeight: 700, fontSize: '2.5rem', lineHeight: '2.5rem' }}>
                                    <AnimatedNumber value={result.totalConsumeRate*100} formatValue={(value) => value.toFixed(2)} />%
                                </span>
                            </div>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <DashboardCard img={require('../../../../images/icons/gold.png')}
                            color="rgba(255, 217, 84, 1)"
                            title={this.getDetail(0).name}
                            rate={this.getDetail(0).totalConsumeRate*100}
                            count={this.getDetail(0).consumeCount} />
                    </Col>
                    <Col span={8}>
                        <DashboardCard img={require('../../../../images/icons/silver.png')}
                            color="rgba(212, 212, 212, 1)"
                            title={this.getDetail(1).name}
                            rate={this.getDetail(1).totalConsumeRate*100}
                            count={this.getDetail(1).consumeCount} />
                    </Col>
                    <Col span={8}>
                        <DashboardCard img={require('../../../../images/icons/bronze.png')}
                            color="rgba(199, 142, 89, 1)"
                            title={this.getDetail(2).name}
                            rate={this.getDetail(2).totalConsumeRate*100}
                            count={this.getDetail(2).consumeCount} />
                    </Col>
                    <Col span={24}>
                        <Card>
                            <h3>各分店領取排行</h3>
                            <ResponsiveTable columns={columns} dataSource={this.state.detail} rowKey="id" />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        )
    }
}

export default RegionDetail